import React, {Dispatch, FunctionComponent} from 'react';
import Modal from "../Modal/Modal";
import userIcon from "../../assets/user.svg";
import {deleteUser} from "../../api/users";
type Props ={
    open:boolean,
    setOpen:Dispatch<boolean>,
    user:{_id:string, name:string},
    recargar:boolean,
    setRecargar:Dispatch<boolean>
}
const DeleteUserModal :FunctionComponent<Props> = (props) => {
    const handleDelete = () => {
        deleteUser(props.user._id).then(()=>{
            props.setRecargar(!props.recargar);
            props.setOpen(false);
        }).catch(err => console.error(err));
    };

    return <Modal open={props.open} setOpen={props.setOpen}>
        <div className={'addFolderModal'}>
            <div className={"modalTitle"} style={{ display: 'flex', alignItems: 'center' }}>
                <img src={userIcon} alt='folder icon' style={{ marginRight: '12px' }} />
                <span> Eliminar Usuario</span>
            </div>
            <span>¿Está seguro que desea eliminar a {props.user.name}?</span>
            <div className={"modalBtns_container"}>
                <button className={"modalBtn"} style={{backgroundColor:"#9B9B9B"}} onClick={()=> props.setOpen(false)}>Cancelar</button>
                <button className={"modalBtn"} style={{backgroundColor:"var(--main-color)"}} onClick={handleDelete}>Eliminar</button>
            </div>
        </div>
    </Modal>
};

export default DeleteUserModal;
