import React, {ChangeEvent, Dispatch, FunctionComponent} from 'react';
import DropDownOption from "../Dropdown/DropdownOption";
import newFolder from "../../assets/newFolder.svg";
import uploadFileIcon from "../../assets/uploadFile.svg";
import uploadFolder from "../../assets/uploadFolder.svg";
import Dropdown from "../Dropdown/Dropdown";

type Props = {
    open:boolean,
    setOpen:Dispatch<boolean>,
    buttonRef:HTMLLabelElement,
    handleCreateFolder:()=>void,
    handleUploadFile:(e:ChangeEvent<HTMLInputElement>)=>void,
}
const PlusButtonDropdown : FunctionComponent<Props> = (props) => {
    return <Dropdown open={props.open} setOpen={props.setOpen} elementRef={props.buttonRef} direction={'top'}>
        <DropDownOption value={'Crear Carpeta'} icon={newFolder} action={props.handleCreateFolder} />
        <DropDownOption
            value={'Subir Archivo'}
            icon={uploadFileIcon}
            action={() => {
                document.getElementById('fileUploadBtn')?.click();
            }}>
            <input type='file' id='fileUploadBtn' accept='.doc, .docx, .pdf, .xls, .xlsx' style={{ display: 'none' }} onChange={props.handleUploadFile} />
        </DropDownOption>

        <DropDownOption
            value={'Subir Carpeta'}
            icon={uploadFolder}
            action={() => {
                document.getElementById('folderUploadBtn')?.click();
            }}>
            <input type='file' id='folderUploadBtn' accept='.zip, .rar, .7zip' style={{ display: 'none' }} onChange={props.handleUploadFile} />
        </DropDownOption>
    </Dropdown>
}


export default PlusButtonDropdown;
