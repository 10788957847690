import React, { Dispatch, FunctionComponent, useEffect, useRef } from 'react';
import './Dropdown.css';
type Props = {
	open: boolean;
	setOpen: Dispatch<boolean>;
	elementRef?: HTMLElement;
	direction?: 'right' | 'top' | 'bottom' | 'left' | 'bottom-end' | 'bottom-left';
	coordinates?:{top:number, left:number}
	className?: string;
	background?: boolean;
	id?: string;
	style?:{}
};

const Dropdown: FunctionComponent<Props> = (props) => {
	const dropDownPanelRef = useRef<HTMLDivElement>(document.createElement('div'));

	useEffect(() => {
		setTimeout(() => {
			if (dropDownPanelRef.current && props.elementRef) {
				switch (props.direction) {
					default:
						dropDownPanelRef.current.style.top =  props.elementRef.getBoundingClientRect().top - dropDownPanelRef.current.getBoundingClientRect().height + 'px';
						dropDownPanelRef.current.style.left = props.elementRef.getBoundingClientRect().left - dropDownPanelRef.current.getBoundingClientRect().width + 'px';
						break;
					case 'right':
						dropDownPanelRef.current.style.top = props.elementRef.getBoundingClientRect().top + 'px';
						dropDownPanelRef.current.style.left = props.elementRef.getBoundingClientRect().left + props.elementRef.getBoundingClientRect().width+ 'px';
						break;
					case 'left':
						dropDownPanelRef.current.style.top = props.elementRef.getBoundingClientRect().top + 'px';
						dropDownPanelRef.current.style.left = props.elementRef.getBoundingClientRect().left - dropDownPanelRef.current.getBoundingClientRect().width + 'px';
						break;
					case 'bottom':
						dropDownPanelRef.current.style.top = props.elementRef.getBoundingClientRect().top + props.elementRef.getBoundingClientRect().height + 'px';
						dropDownPanelRef.current.style.left = props.elementRef.getBoundingClientRect().left + props.elementRef.getBoundingClientRect().width / 2 - dropDownPanelRef.current.getBoundingClientRect().width / 2 + 'px';
						break;
					case 'bottom-end':
						dropDownPanelRef.current.style.top = props.elementRef.getBoundingClientRect().top + props.elementRef.getBoundingClientRect().height + 'px';
						dropDownPanelRef.current.style.left = props.elementRef.getBoundingClientRect().left + 'px';
						break;
					case 'bottom-left':
						dropDownPanelRef.current.style.top = props.elementRef.getBoundingClientRect().top + props.elementRef.getBoundingClientRect().height + 'px';
						dropDownPanelRef.current.style.left = props.elementRef.getBoundingClientRect().left - props.elementRef.getBoundingClientRect().width * 5 + 'px';
						break;
				}
				dropDownPanelRef.current.style.opacity = '1';
				let properties = dropDownPanelRef.current.getBoundingClientRect();
				if( (properties.y + properties.height) > window.innerHeight ){
					dropDownPanelRef.current.style.top = properties.y - 15 -  ( (properties.y + properties.height) - window.innerHeight ) + 'px';
				}		
			}
			if(dropDownPanelRef.current && props.coordinates){
				dropDownPanelRef.current.style.top = props.coordinates.top + 'px';
				dropDownPanelRef.current.style.left = (props.coordinates.left + 5) + 'px';
				dropDownPanelRef.current.style.opacity = '1';
			}

		}, 0);
	}, [props.elementRef, dropDownPanelRef.current, props.open, props.coordinates]);

	function renderDropDownPanel() {
		return (
			<div className={`dropDown_panel ${props.className}`} ref={dropDownPanelRef} style={{ opacity: '0' }}>
				{props.children}
			</div>
		);
	}
	const handleRightClick = (e:React.MouseEvent<HTMLDivElement>) => {
		e.stopPropagation();
		e.preventDefault();
		props.setOpen(false)
	};

	return (
		<div id={props.id} style={props.style} onContextMenu={handleRightClick}>
			{props.open && (
				<React.Fragment>
					{props.background === false ? (
						renderDropDownPanel()
					) : (
						<div className={'modalContainer'}>
							<div className={'dropDownBackground'} onClick={() => props.setOpen(false)} />
							{renderDropDownPanel()}
						</div>
					)}
				</React.Fragment>
			)}
		</div>
	);
};

export default Dropdown;
