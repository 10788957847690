import React, {FunctionComponent} from 'react';
import './DropdownOption.css';

type Props = {
	value: string;
	icon?: string;
	action?: () => void;
	className?: string;
	style?:{},
	disabled?:boolean
};

const DropDownOption: FunctionComponent<Props> = (props) => {
	return (
		<div className={`dropDown_option ${props.icon ? ' withIcon' : ''} ${props.className} ${props.disabled ? "disabled" : ""}`} onClick={props.disabled ? ()=>{} : props.action} style={props.style}>
			{props.icon && <img src={props.icon} alt={props.value} />}
			<span style={{flexGrow:1, textAlign:'left', textTransform:'capitalize'}}>{props.value}</span>
			{props.children}
		</div>
	);
};

export default DropDownOption;
