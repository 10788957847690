import React, {Dispatch, FormEvent, FunctionComponent, useState} from 'react';
import Modal from "../../Modal/Modal";
import {addInstruction, editInstruction} from "../../../api/fileSystem";

type Props = {
    open:boolean,
    setOpen:Dispatch<boolean>,
    instruction:{_id:string, text:string, creation_date:number} | null,
    directorioId:string,
    onEndAction:(instrucciones:{_id:string, text:string, creation_date:number}[])=>void
}
const ModalAddInstruction : FunctionComponent<Props> = (props) => {
    const [instruction, setInstruction] = useState<string>(props.instruction ? props.instruction.text : "");

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        if(props.instruction){
            //Editar la instruccion
            editInstruction(props.instruction._id, props.directorioId, instruction ).then(response => {
                props.setOpen(false);
                props.onEndAction(response.data.directorio.instructions);
            }).catch(err => console.error(err));
        }else{
            //Crear la instrucciom
            addInstruction(instruction, props.directorioId).then(response => {
                props.setOpen(false);
                props.onEndAction(response.data.directorio.instructions);
            }).catch(err => console.error(err));
        }
    }

    return <Modal open={props.open} setOpen={props.setOpen}>
        <form onSubmit={handleSubmit}>
            <div className={'addFolderModal'}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span> Nueva Instrucción</span>
                </div>
                <textarea placeholder={'Instrucciones de la carpeta'} value={instruction} onChange={(e) => setInstruction(e.target.value)}/>
                <div className={'modalBtns_container'}>
                    <button type={"button"} className={'modalBtn'} style={{ backgroundColor: '#9B9B9B' }} onClick={()=>props.setOpen(false)}>
                        Cancelar
                    </button>
                    <button type={"submit"} className={'modalBtn'} style={{ backgroundColor: 'var(--main-color)' }} disabled={!instruction}>
                        {props.instruction ? "Guardar" : "Crear"}
                    </button>
                </div>
            </div>
        </form>
    </Modal>
};


export default ModalAddInstruction;
