import React, {FunctionComponent, useContext, useEffect, useRef, useState} from 'react';
import useDragNDrop from "../../utils/useDragNDrop";
import Dropdown from "../Dropdown/Dropdown";
import DropDownOption from "../Dropdown/DropdownOption";
import {updateFileState, updateFolderState} from "../../api/fileSystem";
import {FileSystemContext} from "../../utils/FileSystemContext";
import FolderIcon from "../../assets/folderBold.svg";
import AccesoDirectoIcon from '../../assets/documentTypes/accesoDirecto.svg';
import PDFDoc from "../../assets/documentTypes/pdfDoc.svg";
import WordDoc from "../../assets/documentTypes/wordDoc.svg";
import ExcelDoc from "../../assets/documentTypes/excelDoc.svg";
import OtherDoc from "../../assets/documentTypes/otherDoc.svg";
import moment from "moment";
import {convertSize} from "../../utils/MathFunctions";
import {FicheroType, Directorio, EnlaceDirecto, FoldersPath} from "../../utils/Types";
import styles from './Fichero.module.css';
import useNavigateLevels from '../../utils/useNavigateLevels';
import blockIcon from '../../assets/Contextmenu/lock.svg';
import { UserContext } from '../../utils/UserContext';

type Props = {
	contenido: FicheroType | Directorio | EnlaceDirecto;
	action: (e :any) => void;
	rightAction: (e: any, element:any) => void;
	view: "grid" | "table";
	disabled?:boolean;
	busqueda?:boolean;
	doubleClick: () => void;
	showDeleted?:boolean
};

const Fichero: FunctionComponent<Props> = (props) => {

	const {handleDrop, handleDragLeave, handleDrag} = useDragNDrop();
	const [openDropdownState, setOpenDropdownState] = useState<boolean>(false);
	const [freeState, setFreeStates] = useState<string[]>([]);
	const stateRef = useRef<HTMLDivElement>(document.createElement('div'));
	const {navigateTo} = useNavigateLevels();

	const { user } = useContext(UserContext);
	const { actualLevel, changeLevel, portaPapeles } = useContext(FileSystemContext);


	const [realElement, setRealElement] = useState<FicheroType | Directorio>((props.contenido as EnlaceDirecto).realDirectory ? (props.contenido as EnlaceDirecto).realDirectory as Directorio : (props.contenido as FicheroType | Directorio));

	useEffect(() => {
		setFreeStates(["sin procesar", "pendiente", "aceptado", "rechazado"].filter(item => item !== (realElement as (FicheroType | Directorio)).state));
	}, [realElement])
	
	useEffect(()=>{
		setRealElement((props.contenido as EnlaceDirecto).realDirectory ? (props.contenido as EnlaceDirecto).realDirectory as Directorio : (props.contenido as FicheroType | Directorio));
	},[props.contenido]);

	const getBackgroundByStyle = (estado : string) =>{
		let background;
		switch (estado) {
			default:
				background = "var(--sinProcesar)";
				break;
			case("pendiente"):
				background = "var(--pendiente)";
				break;
			case("aceptado"):
				background = "var(--aceptado)";
				break;
			case("rechazado"):
				background = "var(--rechazado)";
				break;
		}
		return background;
	};
	const getImagen = (contenido: any) => {
		let img = '';
		if (contenido.files) {
			img = FolderIcon;
			return img;
		}
		if(contenido.realDirectory){
			img = AccesoDirectoIcon;
			return img;
		}else {
			switch (contenido.type) {
				case 'pdf':
					img = PDFDoc;
					break;
				case 'doc':
					img = WordDoc;
					break;
				case 'excel':
					img = ExcelDoc;
					break;
				default:
					img = OtherDoc;
					break;
			}
			return img;
		}
	};

	 const handleChangeState = (newState:string) => {
	 	let promise = new Promise<any>((resolve, reject) => {
			if((realElement as Directorio).files){
				updateFolderState(realElement._id as string,newState).then(response => {
					if (response.status === 200)
						resolve(response.data.directorio);
					else
						reject();
				});
			}else{
				updateFileState(realElement._id as string,newState).then(response => {
					if(response.status === 200)
						resolve(response.data.fichero);
					else
						reject();
				});
			}
		});
	 	promise.then(newElement=>{
			//Encuentro el elemento em el contenido actual.
			let elementIndex : number = actualLevel?.contenido.findIndex(element => element._id === newElement._id) as number;
			//Reemplazo el elemento por el nuevo y actualizo el contenido.
			let newContent = [...actualLevel?.contenido as any[]];
			newContent[elementIndex] = newElement
			changeLevel({...(actualLevel as {_id:string, contenido:any[], name:string}), contenido:newContent});

			//Cierro el dropdown
			setOpenDropdownState(false);
		}).catch(err => console.error(err));
	 };

	 const handleOpenDropDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
	 	e.preventDefault();
	 	e.stopPropagation();
	 	setOpenDropdownState(true);
	 };

	 const handleDropOnFolder = (e:React.DragEvent<HTMLDivElement>) => {
		handleDrop(e, (realElement._id as string), (realElement.name as string), (realElement.foldersPath as any[]).map(item => item._id).concat(realElement._id));
	 };

	 const handleClickRuta = (e: React.MouseEvent<HTMLElement>) => {
		 e.stopPropagation();
		 e.preventDefault();
		 let foldersPath = realElement.foldersPath;
		 if(foldersPath.length > 1){
			const levelToGo : FoldersPath = (foldersPath[foldersPath.length - 1 ] as FoldersPath);
			foldersPath.pop();
			navigateTo(levelToGo._id,levelToGo.name,0,foldersPath as FoldersPath[],levelToGo.instructions );
		 }
		 
	 }

	 const isSelected = () => {
		return portaPapeles.elements.findIndex(elem => elem._id === props.contenido._id) !== -1;
	 }

	return ( <React.Fragment>
			{ props.view === "table" ? <tr onContextMenu={(e) => !props.disabled ? props.rightAction(e, props.contenido) : {}} className={`tableContent ${props.disabled ? styles.disabled : ""} ${isSelected() ? styles.selected : ""}`} onClick={!props.disabled ? props.action : ()=>{}} onDoubleClick={!props.disabled ? props.doubleClick : ()=>{}}>
						<td style={{padding:props.busqueda ? '4px 4px 25px' : '4px'}}>
							<div style={{display:'flex', alignItems:'center'}}>
								<img src={getImagen(props.contenido)} alt={"icono del fichero"} style={{marginRight:'15px'}}/>
								{(realElement as Directorio).blocked && <img src={blockIcon} alt="iconon de bloqueo"/>}
							</div> 
							<span style={{overflow:"hidden", textOverflow:"ellipsis", display:"block", maxWidth:"500px", textAlign:'left'}}>
								{props.contenido.name}
							</span>
							{actualLevel?._id && <div className={styles.estadoIndicator} onContextMenu={(e) => {e.stopPropagation();e.preventDefault();}} style={{background:getBackgroundByStyle((props.contenido as Directorio | FicheroType).state), marginLeft:'12px'}} ref={stateRef} onClick={!props.disabled  && !(!!user?.readOnly?.length && user.readOnly.findIndex(dir => dir === actualLevel?._id || dir === realElement._id) !== -1) ? handleOpenDropDown : ()=>{}}/>}
						</td>
						<td>{moment(realElement.creation_date).format('DD/MM/YYYY')}</td>
						<td>{moment(realElement.updated_date).format('DD/MM/YYYY')}</td>
						{props.showDeleted && <td >{moment(realElement.deleted_date).format('DD/MM/YYYY') || '--'}</td>}
						<td>{convertSize(realElement.size || 0)}</td>
						{ props.busqueda && <td className={styles.rutaBusqueda} onClick={handleClickRuta}>
							{(realElement.foldersPath as any[]).map((elem) => (elem as any).name).join(' > ')}
							</td> }
					</tr>
					:
					<div
						onDragOver={ (e) => e.preventDefault() }
						onDrop={((props.contenido as any).files || (props.contenido as any).realDirectory) && !props.disabled ? (e) => handleDropOnFolder(e) : ()=>{}}
						onDragEnter={((props.contenido as any).files || (props.contenido as any).realDirectory) && !props.disabled ? (e) => handleDrag(e, props.contenido.name) : (e)=>{e.preventDefault();}}
						onDragLeave={((props.contenido as any).files || (props.contenido as any).realDirectory) && !props.disabled ? handleDragLeave : (e) => {e.preventDefault(); e.stopPropagation();}}
						className={`gridFichero_item ${styles.gridFichero_item} ${((props.contenido as any).files || (props.contenido as any).realDirectory) ? "folderItem" : "fileItem"} ${props.disabled ? styles.disabled : ""}  ${isSelected() ? styles.selected : ""}`}
						onClick={!props.disabled ? props.action : ()=>{}}
						onContextMenu={(e) => !props.disabled ? props.rightAction(e, props.contenido) : {}}
						onDoubleClick={!props.disabled ? props.doubleClick : ()=>{}}
						style={{padding:props.busqueda ? '12px 12px 25px' : '12px'}}>
						<div style={{display:'flex', alignItems:'center'}}>
								<img src={getImagen(props.contenido)} alt={"icono del fichero"} style={{marginRight:'5px'}}/>
								{(realElement as Directorio).blocked && <img src={blockIcon} alt="iconon de bloqueo"/>}
						</div>
						<span>{props.contenido.name}</span>
						{actualLevel?._id && <div className={styles.estadoIndicator} onContextMenu={(e) => {e.stopPropagation();e.preventDefault();}} style={{background:getBackgroundByStyle(realElement.state)}} ref={stateRef} onClick={!props.disabled && !(!!user?.readOnly?.length && user.readOnly.findIndex(dir => dir === actualLevel?._id || dir === realElement._id) !== -1) ? handleOpenDropDown : ()=>{}}/>}
						{props.busqueda && <span className={styles.rutaBusqueda} onClick={handleClickRuta}>
							{(realElement.foldersPath as any[]).map((elem) => (elem as any).name).join(' > ')}
							</span>}
					</div>
			}
		{
			actualLevel?._id && openDropdownState && <Dropdown style={{position:'absolute'}} open={openDropdownState} setOpen={setOpenDropdownState} elementRef={stateRef.current} direction={"right"}>
				{freeState.map((item:string, i) => {
					return <DropDownOption key={"stateOption__"+i} value={item} action={()=> handleChangeState(item)} style={{display:'flex', alignItems:'center'}}>
						<div className={styles.estadoIndicator} style={{background:getBackgroundByStyle(item), marginLeft:'12px', justifySelf:'flex-end'}}/>
					</DropDownOption>
				})}
			</Dropdown>
		}
		</React.Fragment>
	);
};

export default Fichero;
