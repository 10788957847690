import React, {Dispatch, useContext} from 'react';
import Modal from "../Modal/Modal";
import {FileSystemContext} from "../../utils/FileSystemContext";
import useFilesActions from '../../utils/useFilesActions';

type Props = {
    open:boolean,
    setOpen:Dispatch<boolean>,
    elementToDelete:any
}

const ModalDelete : React.FunctionComponent<Props> = (props) => {

    const { portaPapeles, changePortapapeles } = useContext(FileSystemContext);

    const {deleteElements} = useFilesActions();

    const handleDeleteElement = () => {
        deleteElements().then(() =>{
            //Limpio el portapapeles
            changePortapapeles({action:null, elements:[]});
            props.setOpen(false);
        }).catch(err => console.error(err));
    };

    return <Modal open={props.open} setOpen={props.setOpen}>
        <div className={"addFolderModal"}>
            <div className={"modalTitle"}>
                <span> Eliminar elementos</span>
            </div>
            <span>¿Está seguro que desea eliminar {portaPapeles.elements.length} elemento/s?</span>
            <div className={"modalBtns_container"}>
                <button className={"modalBtn"} style={{backgroundColor:"#9B9B9B"}} onClick={()=> props.setOpen(false)}>Cancelar</button>
                <button className={"modalBtn"} style={{backgroundColor:"var(--main-color)"}} onClick={handleDeleteElement}>Eliminar</button>
            </div>
        </div>
    </Modal>
};

export default ModalDelete;
