import React, {ChangeEvent, FormEvent, useContext, useState} from 'react';
import './login.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faUser} from '@fortawesome/free-solid-svg-icons';
import {faLock} from '@fortawesome/free-solid-svg-icons';
import {UserContext} from '../../utils/UserContext';
import logoSevillaBlanco from '../../assets/logo-sevilla-blanco.svg';
import {login} from '../../api/authentication';
import {useHistory} from 'react-router-dom';

function Login() {
	const [credentials, setCredentials] = useState({username: '', password: ''});
	const [error, setError] = useState({username: false, password: false});
	const [loginError, setLoginError] = useState(false);

	const {changeUser} = useContext(UserContext);
	let history = useHistory();

	const loginSubmit = (evento: FormEvent) => {
		evento.preventDefault();
		//Esto es una promesa, la función sale de API - Authentication
		login(credentials)
			.then((res) => {
				//controllers - authentication (login )
				if(res.data.user){
					changeUser(res.data.user);
					history.push('/user-area');
				}
			})
			.catch((err) => {
				console.error(err);
				setLoginError(true);
			});
	};

	const userValidation = (evento: ChangeEvent<HTMLInputElement>) => {
		setCredentials({...credentials, username: evento.target.value});
		if (!error.username && evento.target.value.length <= 0) {
			setError({...error, username: true});
		}
		if (error.username && evento.target.value.length > 0) {
			setError({...error, username: false});
		}
	};

	const passwordValidation = (evento: ChangeEvent<HTMLInputElement>) => {
		setCredentials({...credentials, password: evento.target.value});
		if (!error.password && evento.target.value.length <= 0) {
			setError({...error, password: true});
		}
		if (error.password && evento.target.value.length > 0) {
			setError({...error, password: false});
		}
	};

	return (
		<div className="main-container-login">
			<div className="bar-container">
				<div className="login-container">
					<h2> Iniciar sesión</h2>
					<form onSubmit={loginSubmit}>
						{/*Renderización condicional:
                    Creamos un usestate que empieza en false. Cuando pulsas entrar, se hace la promesa, y si devuelve err
                    hemos puesto que cambie el usestate a true. Entre corchetes ponemos dos condiciones, cuando se cumplan las dos
                    se renderiza lo que va dentro. Mientras loginError sea false, al no cumplirse las 2 se mantiene "oculto".
                */}

						{loginError && <span className={'error-message'}>Usuario o contraseña incorrectos.</span>}
						<span>
							<FontAwesomeIcon icon={faUser} className="icon" />
							<input
								className={error.username ? 'error' : ''}
								type="Text"
								placeholder="Usuario"
								onChange={userValidation}
								value={credentials.username}
								required
							/>
						</span>

						<span className={'error-message'}>
							{error.username ? 'El usuario no puede estar vacío.' : ''}
						</span>
						<span>
							<FontAwesomeIcon icon={faLock} className="icon" />
							<input
								className={error.password ? 'error' : ''}
								type="password"
								placeholder="Contraseña"
								onChange={passwordValidation}
								value={credentials.password}
								required
							/>
						</span>
						<span className={'error-message'}>
							{error.password ? 'La contraseña no puede estar vacía.' : ''}
						</span>

						<button> Entrar </button>
					</form>
				</div>

				<div className="title-container">
					{
						//nota para otros proyectos, parece que los svg no cargan si no los importas. Si simplemente pones la dirección en el src, no carga.
					}
					<img src={logoSevillaBlanco} alt="Logo Conservación Viaria Sevilla" />
				</div>
			</div>
		</div>
	);
}

export default Login;
