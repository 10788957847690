import React, {ChangeEvent, Dispatch, FunctionComponent, useContext} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowAltCircleLeft, } from "@fortawesome/free-regular-svg-icons";
import {faChevronRight,faChevronLeft, faHome} from "@fortawesome/free-solid-svg-icons";
import {SearchContext} from "../../utils/SearchContext";
import {FileSystemContext} from "../../utils/FileSystemContext";
import useNavigateLevels from "../../utils/useNavigateLevels";
import styles from './UserAreaHeader.module.css';
import { FilterContext } from '../../utils/FiltersContext';
import Checkbox from '../CheckBox/Checkbox';
import {Instrucciones} from '../../utils/FileSystemContext';

type Props = {
    handleChangeView:Dispatch<"grid" | "table">,
    displayMode:"grid" | "table"
}

const UserAreaHeader : FunctionComponent<Props> = (props) => {
    const { searchContent } = useContext(SearchContext);
    const { actualLevel, levelHistory, appHistory, changeAppHistory } = useContext(FileSystemContext);
    const {goBack, goToHome, navigateTo } = useNavigateLevels();

    const {states, changeStates, changeOrderBy, orderBy} = useContext(FilterContext);

    
    const handleChangeSelectOrder = (e: ChangeEvent<HTMLSelectElement>) => {
        let value = e.target.value as "name" | "creation_date" | "updated_date" | "size";
        changeOrderBy({...orderBy, attr:value});
    };

    const handleChangeSelectDirection = (e:ChangeEvent<HTMLSelectElement>) => {
        let newDirection = e.target.value as "asc" | "desc";
        changeOrderBy({...orderBy, direction:newDirection});
    }

    const handleChangeFilterStates = (property:"sinProcesar"|"pendiente"|"aceptado"|"rechazado") => {
        changeStates({...states, [property]:(!states[property])})
    }

    const handleClickBreadcrumb = (level:{_id:string, name:string, instructions?:Instrucciones[]}, index:number) => {
        let newFolderPath = levelHistory.slice(1,index);
        navigateTo(level._id, level.name, 0, newFolderPath, level.instructions);
        changeAppHistory({type:'pushHistory', element:{_id:level._id as string, name:level.name, foldersPath:newFolderPath, instructions:level.instructions as Instrucciones[]}});
    };

    const handleBackHistory = () => {
        if(appHistory && appHistory.active > 0){
            changeAppHistory({ type:'atras' });
            let levelToGo = appHistory?.history[appHistory.active - 1];
            if(levelToGo._id){
                navigateTo(levelToGo?._id, levelToGo?.name, 0, levelToGo?.foldersPath, levelToGo?.instructions);
            }else{
                goToHome();
            }
        }
    };

    const handleForwardHistory = () => { 
        if(appHistory && (appHistory.history.length - 1)  > appHistory.active){
            changeAppHistory({ type:'avanza' });
            let levelToGo = appHistory?.history[appHistory.active + 1];
            if(levelToGo._id){
                navigateTo(levelToGo?._id, levelToGo?.name, 0, levelToGo?.foldersPath, levelToGo?.instructions);
            }else{
                goToHome();
            }
        }
    };

    const handleGoBack = () => {
        goBack();
        if(actualLevel && actualLevel.foldersPath && actualLevel.foldersPath.length > 0){
            let levelToGo = actualLevel.foldersPath[actualLevel.foldersPath.length - 1];
            let newFolderPath = levelHistory.slice(1,levelHistory.length - 2);
            changeAppHistory({type:'pushHistory', element:{_id:levelToGo._id as string, name:levelToGo.name, foldersPath:newFolderPath, instructions:levelToGo.instructions as Instrucciones[]}});
        }else{
            changeAppHistory({type:'pushHistory', element:{_id:"", name:"Home", foldersPath:[], instructions:[]}});
        }
        
    }

    return <div className={styles.container}>
        <div className={styles.firstRow}>
            <div className={styles.historyNavigation}>
                <FontAwesomeIcon icon={faChevronLeft} onClick={handleBackHistory} className={appHistory && appHistory.active <= 0 ? styles.inactive : ''}/>
                <FontAwesomeIcon icon={faChevronRight} onClick={handleForwardHistory} className={appHistory && appHistory.history.length <= appHistory.active + 1 ? styles.inactive : ''}/>
            </div>
            <div className={styles.filters}>
                <Checkbox type={'checkbox'}checked={states.sinProcesar} rounded={true}
                    name={'sinProcesar'}
                    label={'Sin Procesar'}
                    onChange={()=>handleChangeFilterStates('sinProcesar')}
                />
                <Checkbox type={'checkbox'}checked={states.pendiente} rounded={true}
                    name={'pendiente'}
                    label={'Pendiente'}
                    onChange={()=>handleChangeFilterStates('pendiente')}
                />
                <Checkbox type={'checkbox'}checked={states.aceptado} rounded={true}
                    name={'aceptado'}
                    label={'Aceptado'}
                    onChange={()=>handleChangeFilterStates('aceptado')}
                />
                <Checkbox type={'checkbox'}checked={states.rechazado} rounded={true}
                    name={'rechazado'}
                    label={'Rechazado'}
                    onChange={()=>handleChangeFilterStates('rechazado')}
                />
            </div>
            {
                props.displayMode === "grid" && <div className={styles.orderRow}>
                    <div>
                        <label htmlFor="orderBySelect" style={{fontSize:'.8em'}}>Ordenar por: </label>
                        <select value={orderBy.attr} name="orderBySelect" id="orderBySelect" onChange={handleChangeSelectOrder}>
                            <option value="name">Nombre</option>
                            <option value="creation_date">Fecha creación</option>
                            <option value="updated_date">Fecha modificación</option>
                            <option value="size">tamaño</option>
                        </select>
                    </div>
                <div>
                    <label htmlFor="directionSelect" style={{fontSize:'.8em'}}>Dirección: </label>
                    <select value={orderBy.direction} name="directionSelect" id="directionSelect" onChange={handleChangeSelectDirection}>
                        <option value="asc">Ascendente</option>
                        <option value="desc">Descendente</option>
                    </select>
            </div>
            </div>
            }
             {/* --- Botones ordenación y display --- */}
            <div className='viewModeToggles'>
                <label className='viewModeSVG' onClick={() => props.handleChangeView('grid')} style={{marginRight:'15px'}}>
                    <svg viewBox='0 0 46 19' fill={props.displayMode === 'grid' ? 'grey' : 'lightgrey'}  xmlns='http://www.w3.org/2000/svg' height={'1em'}>
                        <rect width='14' height='5' rx='1' />
                        <rect y='7' width='14' height='5' rx='1' />
                        <rect x='16' width='14' height='5' rx='1' />
                        <rect x='16' y='7' width='14' height='5' rx='1' />
                        <rect x='32' width='14' height='5' rx='1' />
                        <rect x='32' y='7' width='14' height='5' rx='1' />
                        <rect y='14' width='14' height='5' rx='1' />
                        <rect x='16' y='14' width='14' height='5' rx='1' />
                        <rect x='32' y='14' width='14' height='5' rx='1' />
                    </svg>
                </label>
                {/* PARA AÑADIR LAS CLASES CONDICIONALES: <label className= {`viewModeSVG ${displayMode == 'table' ? 'grey' : 'lightgrey'} `} onClick={() => toggleDisplayMode('table')}> */}
                <label className='viewModeSVG' onClick={() => props.handleChangeView('table')}>
                    <svg  viewBox='0 0 46 19' fill={props.displayMode === 'table' ? 'grey' : 'lightgrey'} xmlns='http://www.w3.org/2000/svg' height={'1em'}>
                        <rect x='46' y='19' width='37' height='5' rx='1' transform='rotate(-180 46 19)' />
                        <rect x='46' y='12' width='37' height='5' rx='1' transform='rotate(-180 46 12)' />
                        <rect x='7' y='19' width='7' height='5' rx='1' transform='rotate(-180 7 19)' />
                        <rect x='7' y='12' width='7' height='5' rx='1' transform='rotate(-180 7 12)' />
                        <rect x='46' y='5' width='37' height='5' rx='1' transform='rotate(-180 46 5)' />
                        <rect x='7' y='5' width='7' height='5' rx='1' transform='rotate(-180 7 5)' />
                    </svg>
                </label>
            </div>
        </div>

        {searchContent === null && <div className={styles.navigation}>
            {levelHistory.length > 1 && <FontAwesomeIcon icon={faArrowAltCircleLeft} size={'2x'} style={{
                cursor: 'pointer'
            }} onClick={handleGoBack}/>}
            <div className={'breadcrumb'}>
                <FontAwesomeIcon icon={faHome} className={'breadcrumb_option'} onClick={goToHome}/>
                {levelHistory.map((level, i) => {
                    return (
                        <div key={'brOption__' + i} className={'breadcrumb_option'}
                             onClick={() => handleClickBreadcrumb(level, i)}>
                            <FontAwesomeIcon icon={faChevronRight} style={{margin: '0 8px'}}/>
                            <span>{level.name}</span>
                        </div>
                    );
                })}
            </div>
        </div>
        }
    </div>
}

export default UserAreaHeader;
