import React, {useCallback, useEffect, useRef, useState, useContext} from 'react';
import TablaFicheros from "../Nivel/TablaFicheros/TablaFicheros";
import Dropdown from "../Dropdown/Dropdown";
import DropDownOption from "../Dropdown/DropdownOption";
import deleteIcon from "../../assets/Contextmenu/Delete.svg";
import resetIcon from '../../assets/rest_element.svg';
import Modal from "../Modal/Modal";
import Header from "../Header/Header";
import {getAllDeletedDirectorios, getAllDeletedFiles,
    hardDeleteAllDirectorios,
    hardDeleteAllFiles, hardDeleteFile, hardDeleteFolder, restaurarDirectorio, restaurarFile
} from "../../api/fileSystem";
import Button from '../Button/Button';
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {FilterContext} from '../../utils/FiltersContext';
import {FicheroType, Directorio} from '../../utils/Types';

function findIdOnAllParents(node: HTMLElement, id: string) {
    while (node.parentElement) {
        node = node.parentElement;
        if (node.id === id) return true;
    }
    return false;
}

const Papelera = () => {
    const fileRef = useRef(document.createElement('div'));
    const [openDropdown, setOpenDropdown] = useState(false);
    const [haveToOpenNew, setHaveToOpenNow] = useState(false);
    const [elementActive, setElementActive] = useState<any>(null);
    const [openModal, setOpenModal] = useState(false);

    const [contenido, setContenido] = useState<(FicheroType | Directorio)[]>([]);

    const { orderBy } = useContext(FilterContext);

    const loadContent = useCallback(() => {
        let getContentPromises = [];
        getContentPromises.push(getAllDeletedDirectorios().then(response =>  {return response.data.directorios}));
        getContentPromises.push(getAllDeletedFiles().then(response => {return response.data.ficheros}));
        Promise.all(getContentPromises).then((values:any) => {
            let folders = values[0];
            let files = values[1];
            let allContent = folders.concat(files);
            setContenido(allContent);
        }).catch(err => console.error(err));
    }, []);
    
    useEffect(() => {
        //cargar contenido
        loadContent();

    },[loadContent]);

    //Ordenar contenido
    useEffect(() => {
        if(contenido){
            let sortedItems = [];
            if (orderBy.attr === "size" || orderBy.attr === "creation_date" || orderBy.attr === "updated_date") {
                sortedItems = [...contenido].sort((a, b) => { return orderBy.direction === "asc" ? (a as any)[orderBy.attr] - (b as any)[orderBy.attr] : (b as any)[orderBy.attr] - (a as any)[orderBy.attr] });
            } else {
                sortedItems = [...contenido].sort((a, b) => { return orderBy.direction === "asc" ? a.name.localeCompare(b.name, undefined, { numeric: true }) : b.name.localeCompare(a.name, undefined, { numeric: true }) });
            }
            setContenido(sortedItems);
        }
    }, [orderBy])
    //Funcion que controla el click derecho en un elemento
    const rightClickAction = (e: any, element: any) => {
        e.stopPropagation();
        e.preventDefault();
        //Cambio la referencia para el dropdown
        fileRef.current = e.target;
        //Pongo la variable de volver a mostrar en true.
        setHaveToOpenNow(true);
        //Oculto el dropdown siempre por si está abierto en otro lado. Esto es porque lo voy a abrir en la función de efecto cuando haya la combinación [openDropDown==false && haveToOpen==true], que va ser el indicativo de que tengo que abrirlo.
        setOpenDropdown(false);
        //Pongo como elemento activo donde he clickado
        setElementActive(element);
    };

    //Funcion para controlar el click en cualquier lado de la pantalla menos en el menú.
    const clickWindowAndCloseDropdown = (e: MouseEvent) => {
        //La funcion findIdOnAllParents está definida arriba y va buscar por el id que le he puesto a este dropdown.
        if (!findIdOnAllParents(e.target as HTMLElement, 'fileMenu__dropdown')) {
            //Si he clickado fuera cierro el dropdown y elimino los "listeners" para no ejecutar la funcion mas veces de las necesarias.
            setOpenDropdown(false);
            window.removeEventListener('click', clickWindowAndCloseDropdown);
            window.removeEventListener('contextmenu', clickWindowAndCloseDropdown);
        }
    };

    //Funcion que se va ejecutar cuando abra el dropdown.
    useEffect(() => {
        if (openDropdown) {
            //Si el dropdown se ha abiero, voy a poner a escuchar los eventos de click en la ventana [Y se va ejecutar la función definida justo arriba (clickWindowAndCloseDropdown)]
            window.addEventListener('click', clickWindowAndCloseDropdown);
            window.addEventListener('contextmenu', clickWindowAndCloseDropdown);
            setHaveToOpenNow(false);
        }else{
            setElementActive(null);
        }
    }, [openDropdown]);

    //Funcion que se va ejecutar cuando se actualice el haveToOpen o el openDropdown.
    useEffect(() => {
        if (!openDropdown && haveToOpenNew) {
            //Si existe la combinacion de openDropDown==false y haveToOpen==true (se ha puesto así cuando hago click derecho) ==> Abro el dropdown.
            setOpenDropdown(true);
        }
    }, [openDropdown, haveToOpenNew]);


    const handleVaciarPapelera = (e:React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        setOpenModal(false);
        hardDeleteAllDirectorios().then(response => {
            console.log(response);
            if(response.status === 200){
                hardDeleteAllFiles().then(response => {
                    console.log(response);
                    setContenido([]);
                }).catch(err => {
                    console.error(err);
                });
            }
        }).catch(err => {
            console.error(err);
        });
    };

    const handleDeleteElement = (e:React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        setOpenModal(false);
        if(elementActive.files){
            hardDeleteFolder(elementActive._id).then(() => {
                loadContent();
                setElementActive(null);
            }).catch(err => console.error(err));
        }else{
            hardDeleteFile(elementActive._id).then(() => {
                loadContent();
                setElementActive(null);
            }).catch(err => console.error(err));
        }
        setOpenDropdown(false);
    };

    const handleRestaurarElement = () => {
        if(elementActive.files){
            restaurarDirectorio(elementActive._id).then(response => {
                loadContent();
                setElementActive(null);
            }).catch(err => console.error(err));
        }else{
            restaurarFile(elementActive._id).then(response => {
                loadContent();
                setElementActive(null);
            }).catch(err => console.error(err));
        }
        setOpenDropdown(false);
    };

    const handleClickElement = (contenido: any) => {
        console.log(contenido);
    }

    return <React.Fragment>
        <Header />
        <div style={{marginTop:'30px'}}>
            <div style={{margin:'15px 30px', textAlign:'right'}}>
                <Button contenido={"Vaciar papelera"} action={()=>{setOpenModal(true)}} icon={faTrash}/>
            </div>
            <div className={"tablaFicheros"} >
                <TablaFicheros contenido={contenido} rightAction={rightClickAction} clickAction={handleClickElement} doubleClick={() => {}} showDeleted={true}/>
                {openDropdown &&
                <Dropdown open={openDropdown} setOpen={setOpenDropdown} elementRef={fileRef.current} direction={"bottom"} background={false} id={"fileMenu__dropdown"}>
                    { <DropDownOption value={"Restaurar"} icon={resetIcon} action={handleRestaurarElement}/>}
                    {<DropDownOption value={"Eliminar"} icon={deleteIcon} action={()=>{setOpenModal(true)}}/>}
                </Dropdown>
                }
                {openModal &&
                    <Modal open={openModal} setOpen={setOpenModal}>
                        <div className={"addFolderModal"}>
                            <div className={"modalTitle"}>
                                <span>{elementActive ? "Eliminar fichero" : "Vaciar papelera"}</span>
                            </div>
                            <span>¿Está seguro que desea continuar? Esta acción es irreparable.</span>
                            <div className={"modalBtns_container"}>
                                <button className={"modalBtn"} style={{backgroundColor:"#9B9B9B"}} onClick={()=> setOpenModal(false)}>Cancelar</button>
                                <button className={"modalBtn"} style={{backgroundColor:"var(--main-color)"}} onClick={elementActive ? handleDeleteElement : handleVaciarPapelera}>Confirmar</button>
                            </div>
                        </div>
                    </Modal>
                }
            </div>
        </div>
    </React.Fragment>
};

export default Papelera;
