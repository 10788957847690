import { useContext } from 'react';
import { FileSystemContext } from "./FileSystemContext";
import {
    copyFile,
    copyFolder,
    createFile,
    createFolder,
    moveAccesoDirecto,
    moveFile,
    moveFolder,
    updateFolderPath
} from "../api/fileSystem";
import { Directorio, FicheroType, EnlaceDirecto } from "./Types";
import { deleteAccesoDirecto, softDeleteFile, softDeleteFolder } from "../api/fileSystem";
import { AxiosResponse } from 'axios';


function useFilesActions() {

    const { actualLevel, changeLevel, levelHistory, portaPapeles } = useContext(FileSystemContext);


    const addContent = (contentToAdd: (FicheroType | Directorio | EnlaceDirecto)[]) => {
        let actualContent = [...(actualLevel?.contenido as any[])];
        actualContent = actualContent.concat(contentToAdd);
        changeLevel({ ...(actualLevel as { _id: string; contenido: any[]; name: string }), contenido: actualContent });
    };

    const uploadFile = (fileToUpload: File, directorio: string, nameDirectorio: string, newFoldersPath?: string[], namePaths?: string): Promise<FicheroType> => {
        let typeOfFile = fileToUpload.name.includes(".doc") ? "doc" : fileToUpload.name.includes(".pdf") ? "pdf" : fileToUpload.name.includes(".xls") ? "excel" : "other";
        let path = namePaths ? namePaths : levelHistory.map(level => level.name).join("/") + "/" + (actualLevel?._id !== directorio ? nameDirectorio : "");
        return new Promise((resolve, reject) => {
            createFile(fileToUpload, typeOfFile, directorio, fileToUpload.name, fileToUpload.size, path).then(response => {
                let foldersPath = newFoldersPath ? newFoldersPath : levelHistory.map(level => level._id).slice(1);

                if (actualLevel?._id !== directorio && !newFoldersPath)
                    foldersPath.push(directorio);

                updateFolderPath(response.data.file._id, foldersPath).then(response => {
                    resolve(response.data.fichero);
                }).catch(err => reject(err));
            }).catch(err => reject(err));
        })
    };

    const createNewFolder = (newFolderName: string, instructions: string, folderIdDst: string, foldersPath: string[]): Promise<Directorio> => {
        return new Promise<Directorio>((resolve, reject) => {
            createFolder(newFolderName, instructions, folderIdDst, foldersPath).then((response) => {
                resolve(response.data.directorio);
            }).catch((err) => reject(err));
        });
    };

    const deleteElements = (): Promise<void> => {
        return new Promise<void>(async (resolve, reject) => {
            let deletePromises: Promise<AxiosResponse>[] = [];

            for (let i = 0; i < portaPapeles.elements.length; i++) {
                const actualElement = portaPapeles.elements[i];
                if ((actualElement as Directorio).files) {
                    deletePromises.push(softDeleteFolder((actualElement as Directorio)._id as string));
                    continue;
                }
                if (!(actualElement as Directorio).files && !(actualElement as EnlaceDirecto).realDirectory) {
                    deletePromises.push(softDeleteFile((actualElement as FicheroType)._id as string));
                    continue;
                }
                if ((actualElement as EnlaceDirecto).realDirectory) {
                    deletePromises.push(deleteAccesoDirecto((actualElement as EnlaceDirecto)._id as string));
                    continue;
                }
            }
            Promise.all(deletePromises).then(() => {
                let newContent = [...actualLevel?.contenido as any[]];
                portaPapeles.elements.forEach(elementToDelete => {
                    //Encuentro el elemento em el contenido actual.
                    let elementIndex: number = newContent.findIndex(element => element._id === elementToDelete._id) as number;
                    //Reemplazo el elemento por el nuevo y actualizo el contenido.
                    console.log(elementIndex);
                    console.log(newContent[elementIndex]);
                    newContent.splice(elementIndex, 1);
                });
                console.log(newContent);
                changeLevel({ ...(actualLevel as { _id: string, contenido: any[], name: string }), contenido: newContent });
                resolve();
            }).catch(err => reject(err));
        })
    }

    const pasteElement = (): Promise<void> => {
        return new Promise<void>(async (resolve, reject) => {
            let actualLevelId = actualLevel?._id as string;
            let ficheros: string[] = [];
            let contentToAdd: FicheroType[] = [];
            if (portaPapeles.action === "move") {
                let movePromises: Promise<FicheroType | Directorio | EnlaceDirecto>[] = [];
                for (let i = 0; i < portaPapeles.elements.length; i++) {
                    let element = portaPapeles.elements[i];
                    if ((element as Directorio).files) {
                        movePromises.push(moveFolder(element._id as string, actualLevelId).then(response => {
                            return response.data.directorioMovido;
                        }));
                        continue;
                    }
                    if ((element as EnlaceDirecto).realDirectory) {
                        movePromises.push(moveAccesoDirecto(element._id as string, actualLevelId).then(response => {
                            return response.data.enlaceDirecto;
                        }));
                        continue;
                    }
                    else {
                        //Promesa para que no fallen los ficheros y moverlos de uno en uno. 
                        ficheros.push(element._id as string);
                        continue;
                    }
                }
                for (let i = 0; i < ficheros.length; i++) {
                    let response = await moveFile(ficheros[i], actualLevelId);
                    contentToAdd.push(response.data.ficheroMovido);
                    continue;
                }
                Promise.all(movePromises).then(responses => {
                    addContent(responses.concat(contentToAdd));
                    resolve();
                }).catch(err => reject(err));

            } else {
                let copyPromises: Promise<FicheroType | Directorio>[] = [];
                for (let i = 0; i < portaPapeles.elements.length; i++) {
                    let element = portaPapeles.elements[i];
                    if ((element as Directorio).files) {
                        copyPromises.push(copyFolder(element._id as string, actualLevelId).then(response => {
                            return response.data.directorioCopiado;
                        }));
                        continue;
                    }
                    if ((element as EnlaceDirecto).realDirectory) {
                        //Es un enlace directo... ¿Crep uno nuevo donde sea? ¿No hago nada?
                        continue;
                    }
                    else {
                        copyPromises.push(copyFile(element._id as string, actualLevelId).then(response => {
                            return response.data.ficheroCopiado;
                        }));
                        continue;
                    }
                }
                Promise.all(copyPromises).then((responses) => {
                    addContent(responses);
                    resolve();
                }).catch(err => reject(err));
            }
        });


    };


    return { uploadFile, createNewFolder, pasteElement, addContent, deleteElements }
}

export default useFilesActions;
