import React, {Dispatch, FormEvent, FunctionComponent, useContext, useState} from 'react';
import folder from "../../assets/folderBold.svg";
import Modal from "../Modal/Modal";
import useFilesActions from "../../utils/useFilesActions";
import {FileSystemContext} from "../../utils/FileSystemContext";
import {Directorio} from "../../utils/Types";

type Props = {
    open:boolean,
    setOpen:Dispatch<boolean>
}
const ModalCreateFolder : FunctionComponent<Props> = (props) => {

    const [newFolderName, setNewFolderName] = useState('');
    const [instructions, setInstructions] = useState('');
    const { createNewFolder, addContent } = useFilesActions();
    const {actualLevel, levelHistory} = useContext(FileSystemContext);

    const handleCreateFolder = (e : FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        let foldersPath = levelHistory.map((level) => level._id);
        foldersPath.shift();
        createNewFolder(newFolderName, instructions,  actualLevel?._id as string, foldersPath).then((directorio : Directorio) => {
            addContent([directorio]);
            props.setOpen(false);
        }).catch(err => console.error(err));
    };

    return <Modal open={props.open} setOpen={props.setOpen}>
        <form onSubmit={handleCreateFolder}>
            <div className={'addFolderModal'}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={folder} alt='folder icon' style={{ marginRight: '12px' }} />
                    <span> Nueva Carpeta</span>
                </div>
                <input type='text' placeholder='Nombre de la carpeta...' value={newFolderName} onChange={(e) => setNewFolderName(e.target.value)} />
                <textarea placeholder={'Instrucciones de la carpeta'} value={instructions} onChange={(e) => setInstructions(e.target.value)}/>
                <div className={'modalBtns_container'}>
                    <button type={"button"} className={'modalBtn'} style={{ backgroundColor: '#9B9B9B' }} onClick={()=>props.setOpen(false)}>
                        Cancelar
                    </button>
                    <button type={"submit"} className={'modalBtn'} style={{ backgroundColor: 'var(--main-color)' }}>
                        Crear
                    </button>
                </div>
            </div>
        </form>
    </Modal>
}

export default ModalCreateFolder;
