import React, {Dispatch, FunctionComponent} from 'react';
import Modal from "../../Modal/Modal";
import moment from 'moment';
import styles from './ModalInfo.module.css';

type Props = {
    open:boolean,
    setOpen:Dispatch<boolean>,
    element:any
}
const ModalInfo : FunctionComponent<Props> = (props) => {


    const getSizeFormat = (size:number) => {
        return size > 1000 ? (size > 1000000 ? (size/1000000).toFixed(2) + "MB" :  (size/976).toFixed(2) + "KB") : size.toFixed(2) + "B"
    };

    return <Modal open={props.open} setOpen={props.setOpen}>
        <div className={"addFolderModal"}>
            <div className={"modalTitle"}>
                <span>Información</span>
            </div>
            <table className={styles.tableInfo}>
                <thead>
                <tr>
                    <th colSpan={2}>General</th>
                </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Nombre: </td>
                        <td>{props.element.name}</td>
                    </tr>
                    <tr>
                        <td>Tamaño: </td>
                        <td>{props.element.size ? getSizeFormat(props.element.size) : '0B'}</td>
                    </tr>
                    <tr>
                        <td>Ruta: </td>
                        <td>{props.element.foldersPath?.length > 0 ? props.element.foldersPath.map((path:{_id:string, name:string}) => path.name).join('/') : ""}/{props.element.name}</td>
                    </tr>
                    <tr>
                        <td>Estado: </td>
                        <td>{props.element.state}</td>
                    </tr>
                </tbody>
            </table>
            <table className={styles.tableInfo}>
                <thead>
                <tr>
                    <th colSpan={2}>Actividad</th>
                </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Creado el: </td>
                        <td>{moment(props.element.creation_date).format("DD/MM/YYYY")}</td>
                    </tr>
                    <tr>
                        <td>Actualizado por última vez: </td>
                        <td>{moment(props.element.updated_date).format("DD/MM/YYYY")}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </Modal>
};

export default ModalInfo;
