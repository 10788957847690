import {useContext, useEffect} from 'react';
import {FileSystemContext} from "./FileSystemContext";
import {getFirstContent, getFolderContent} from "../api/fileSystem";
import {FilterContext} from "./FiltersContext";
import {SearchContext} from "./SearchContext";


function useNavigateLevels(){
    const { actualLevel, changeLevel, levelHistory, filesLoaded, changeFilesLoaded, portaPapeles, changePortapapeles } = useContext(FileSystemContext);
    const { filters, orderBy, states } = useContext(FilterContext);

    const {changeSearchContent, changeSearchValue} = useContext(SearchContext);

    useEffect(() => {
        if(actualLevel?._id && filesLoaded > 0){
            navigateTo(actualLevel._id, actualLevel.name,filesLoaded, actualLevel.foldersPath, actualLevel.instructions)
        }
    }, [filesLoaded]);

    const goToHome = () => {
        changeSearchValue("");
        getFirstContent().then(directoriosIniciales => {
            changeSearchContent(null);
            changeLevel({_id:"", contenido:directoriosIniciales.data.directorios, name:"Home"});
        }).catch(err => console.error(err));

        if(!portaPapeles.action){
			changePortapapeles({action:null, elements:[]});
		}
    };

    const navigateTo = (levelId:string, levelName:string,filesToLoad:number, foldersPath?:{_id:string, name:string, instructions?:{_id:string,text:string, creation_date:number}[]}[], instructions?:{_id:string,text:string, creation_date:number}[]) => {
        changeSearchValue("");
        if(levelId){
            getFolderContent(orderBy.attr, levelId, orderBy.direction, 6000, 0, filters.pdf,filters.doc, filters.other, filters.excel, filters.folders, states.sinProcesar, states.pendiente, states.aceptado, states.rechazado).then(response => {
                let sortInstructions = instructions ? instructions.sort((a,b) => b.creation_date - a.creation_date) : actualLevel?.instructions;
                let content = response.data.contenido;
                console.log(content);
                if(filesToLoad > 0){
                   content = actualLevel?.contenido.concat(content);
                }
                if(filesToLoad === 0){
                    changeFilesLoaded(0);
                }
                console.log(content);
                changeLevel({_id:levelId,contenido:content,name:levelName,foldersPath, instructions:sortInstructions});
                changeSearchContent(null);
            }).catch(err => console.error(err));
        }else{
            goToHome();
        }

        if(!portaPapeles.action){
			changePortapapeles({action:null, elements:[]});
		}
    };

    const goBack = () => {
        if(actualLevel && actualLevel.foldersPath && actualLevel.foldersPath.length > 0){
            let levelToGo = actualLevel?.foldersPath[actualLevel?.foldersPath.length - 1];
            let newFolderPath = levelHistory.slice(1,levelHistory.length - 2);
            console.log(newFolderPath);
            navigateTo(levelToGo._id, levelToGo.name,0, newFolderPath, levelToGo.instructions);
        }else{
            goToHome();
        }
       
    };

    const downloadFile = (url: string, name: string) => {
        let link = document.createElement('a');
        link.download = name;
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        link.remove();
    };


    return { navigateTo, goBack, goToHome, downloadFile}
}

export default useNavigateLevels;
