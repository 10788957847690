import React, {FunctionComponent, Dispatch} from 'react';
import ReactDOM from 'react-dom';
import './Modal.css';
type Props = {
	open: boolean,
    setOpen: Dispatch<boolean>
};

const Modal: FunctionComponent<Props> = (props) => {
	if (!document.getElementById('modal-container')) {
		let element = document.createElement('div');
		element.id = 'modal-container';
		document.querySelector('#root')?.appendChild(element);
    }

    const ModalContent = () => {
        return (
            <div className="modalContainer">
                {props.open && <React.Fragment>
                <div className={"backgroundModal"} onClick={() => props.setOpen(false)}></div>
                    <div className={"modalBody"}>
                        {props.children}
                    </div>
                    </React.Fragment>}
            </div>
        );
    };

    return ReactDOM.createPortal(ModalContent(),(document.getElementById('modal-container') as HTMLElement));
};


export default Modal;
