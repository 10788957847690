import React, { FunctionComponent, useContext, useEffect, useRef, useState } from 'react';

//Styles
import './Nivel.css';

//Icons
import PDFDoc from '../../assets/documentTypes/pdfDoc.svg';
import WordDoc from '../../assets/documentTypes/wordDoc.svg';
import ExcelDoc from '../../assets/documentTypes/excelDoc.svg';
import OtherDoc from '../../assets/documentTypes/otherDoc.svg';
import FolderIcon from '../../assets/folderBold.svg';
import infoIcon from '../../assets/Contextmenu/Info.svg';
import renameIcon from '../../assets/Contextmenu/Edit.svg';
import copyIcon from '../../assets/Contextmenu/Copy.svg';
import moveIcon from '../../assets/Contextmenu/cil_cut.svg';
import deleteIcon from '../../assets/Contextmenu/Delete.svg';
import pasteIcon from '../../assets/Contextmenu/Paste.svg';
import downloadIcon from '../../assets/download_cloud.svg';
import enlaceIcon from '../../assets/Contextmenu/bi_link.svg';
import accesoDirectoIcon from '../../assets/Contextmenu/akar-icons_link-out.svg';
import blockIcon from '../../assets/Contextmenu/lock.svg';
import unBlockIcon from '../../assets/Contextmenu/lock-open.svg';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

//Components
import Fichero from './Fichero';
import Dropdown from '../Dropdown/Dropdown';
import DropDownOption from '../Dropdown/DropdownOption';
import ModalRename from './ModalRename';
import ModalDelete from './ModalDelete';
import ModalInfo from './ModalInfo/ModalInfo';
import TablaFicheros from './TablaFicheros/TablaFicheros';
import ModalEnlace from './ModalEnlace';

//Utils
import useDragNDrop from '../../utils/useDragNDrop';
import useFilesActions from "../../utils/useFilesActions";
import useNavigateLevels from '../../utils/useNavigateLevels';

//Contexts
import { SearchContext } from '../../utils/SearchContext';
import { FileSystemContext } from '../../utils/FileSystemContext';
import { FilterContext } from '../../utils/FiltersContext';
import {UserContext} from '../../utils/UserContext';

//Api
import {blockFolder, createAccesoDirecto, downloadFolderAsZip, generateEnlaceToDownload, unBlockFolder} from "../../api/fileSystem";

//Types
import { Directorio, FoldersPath, Instrucciones, FicheroType, EnlaceDirecto } from '../../utils/Types';


type Props = { displayMode: string };

function findIdOnAllParents(node: HTMLElement, id: string) {
	while (node.parentElement) {
		node = node.parentElement;
		if (node.id === id) return true;
	}
	return false;
}

const Nivel: FunctionComponent<Props> = (props) => {

	//Contexts
	const { user } = useContext(UserContext);
	const { filters, orderBy, states } = useContext(FilterContext);
	const { searchContent } = useContext(SearchContext);

	//Utils
	let { actualLevel,changeLevel, changePortapapeles, portaPapeles, filesLoaded, changeAppHistory } = useContext(FileSystemContext);
	const fileRef = useRef(document.createElement('div'));
	const { navigateTo, goToHome, downloadFile } = useNavigateLevels();
	const { handleDrop, handleDrag, handleDragLeave } = useDragNDrop();
	const {pasteElement, addContent} = useFilesActions();

	//Modals
	const [modalRename, setModalRename] = useState(false);
	const [modalDelete, setModalDelete] = useState(false);
	const [modalInfo, setModalInfo] = useState(false);
	const [modalEnlace, setModalEnlace] = useState(false);

	//Local states
	const [enlaceToDownload, setEnlaceToDownload] = useState("");
	const [openLevelDropdown, setOpenLevelDropdown] = useState(false);
	const [rightClickPosition, setRightClickPosition] = useState<{top:number, left:number}>({top:0, left:0});

	//Variables de estado: La primera para abrir el dropdown, la segunda para controlar el volver a abrirlo tras cerrarlo (Para cuando pincho en otro elemento.);
	// La ultima variable es donde voy a guardar el valor del elemento que voy a hacerle algo (fichero o carpeta)
	const [openDropdown, setOpenDropdown] = useState(false);
	const [haveToOpenNew, setHaveToOpenNow] = useState(false);
	const [elementActive, setElementActive] = useState<any>(null);

	//Set link to download
	useEffect(() => {
		if(!modalEnlace){
			setEnlaceToDownload("");
		}
	}, [modalEnlace])

	useEffect(() => {
		if (!actualLevel || actualLevel._id !== '') {
			//Carga el contenido principal.
			goToHome();
		}
	}, []);

	/*Esta función se va ejecutar cada vez que cambien los filtros. 
	* Básicamente va cargar de nuevo el contenido en función de los filtros.
	*/
	useEffect(() => {
		if (actualLevel?._id !== '') {
			navigateTo(actualLevel?._id as string, actualLevel?.name as string, filesLoaded);
		}
	}, [filters, orderBy, states]);


	const getImagen = (contenido: any) => {
		let img = '';
		if (contenido.files) {
			img = FolderIcon;
		} else {
			switch (contenido.type) {
				case 'pdf':
					img = PDFDoc;
					break;
				case 'doc':
					img = WordDoc;
					break;
				case 'excel':
					img = ExcelDoc;
					break;
				default:
					img = OtherDoc;
					break;
			}
		}
		return img;
	};

	/* ---- Lógica para abrir el menú del fichero con el botón secundario ---- */


	//Funcion que controla el click derecho en un elemento
	const rightClickAction = (e: any, element: any) => {
		e.stopPropagation();
		e.preventDefault();
		//Cambio la referencia para el dropdown
		fileRef.current = e.target;
		//Pongo la variable de volver a mostrar en true.
		setHaveToOpenNow(true);
		//Oculto el dropdown siempre por si está abierto en otro lado. Esto es porque lo voy a abrir en la función de efecto cuando haya la combinación [openDropDown==false && haveToOpen==true], que va ser el indicativo de que tengo que abrirlo.
		setOpenDropdown(false);
		//Pongo como elemento activo donde he clickado
		setElementActive(element);
	};

	//Funcion para controlar el click en cualquier lado de la pantalla menos en el menú.
	const clickWindowAndCloseDropdown = (e: MouseEvent) => {
		//La funcion findIdOnAllParents está definida arriba y va buscar por el id que le he puesto a este dropdown.
		if (!findIdOnAllParents(e.target as HTMLElement, 'fileMenu__dropdown')) {
			//Si he clickado fuera cierro el dropdown y elimino los "listeners" para no ejecutar la funcion mas veces de las necesarias.
			setOpenDropdown(false);
			window.removeEventListener('click', clickWindowAndCloseDropdown);
			window.removeEventListener('contextmenu', clickWindowAndCloseDropdown);
		}
	};

	//Funcion que se va ejecutar cuando abra el dropdown.
	useEffect(() => {
		if (openDropdown) {
			//Si el dropdown se ha abiero, voy a poner a escuchar los eventos de click en la ventana [Y se va ejecutar la función definida justo arriba (clickWindowAndCloseDropdown)]
			window.addEventListener('click', clickWindowAndCloseDropdown);
			window.addEventListener('contextmenu', clickWindowAndCloseDropdown);
			setHaveToOpenNow(false);
		}
	}, [openDropdown]);

	//Funcion que se va ejecutar cuando se actualice el haveToOpen o el openDropdown.
	useEffect(() => {
		if (!openDropdown && haveToOpenNew) {
			//Si existe la combinacion de openDropDown==false y haveToOpen==true (se ha puesto así cuando hago click derecho) ==> Abro el dropdown.
			setOpenDropdown(true);
		}
	}, [openDropdown, haveToOpenNew]);

	//Funcion que se va ejecutar cuando se abra alguno de los modals.
	useEffect(() => {
		if (modalRename || modalDelete || modalInfo) {
			//Si tengo algun modal abierto cierro el dropdown.
			setOpenDropdown(false);
		}
	}, [modalRename, modalDelete, modalInfo]);

	const handleOpenLevelMenu = (e:React.MouseEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();
		setRightClickPosition({top:e.pageY, left:e.pageX});
		setOpenLevelDropdown(true);
	};


	//Funcion para copiar el elemento.
	const handleCopyElement = () => {
		changePortapapeles({...portaPapeles, action:"copy"});
		setOpenDropdown(false);
	};

	//Funcion para cortar el elemento.
	const handleCutElement = () => {
		changePortapapeles({...portaPapeles, action:"move"});
		setOpenDropdown(false);
		let newContent = [...actualLevel?.contenido as (Directorio | FicheroType | EnlaceDirecto)[]];
		for(let i=0;i<portaPapeles.elements.length; i++){
			//Encuentro el elemento em el contenido actual.
			let elementIndex : number = newContent.findIndex(element => element._id === portaPapeles.elements[i]._id as string) as number;
			//Reemplazo el elemento por el nuevo y actualizo el contenido.
			if(elementIndex !== -1){
				newContent.splice(elementIndex,1);
			}
		}
		changeLevel({...(actualLevel as {_id:string, contenido:any[], name:string}), contenido:newContent});
	};

	const handlePasteElement = () => {
		pasteElement().then(() => {
			setOpenLevelDropdown(false);
			changePortapapeles({action:null, elements:[]});
		}).catch(err => console.error(err));
	};



	const renderFicheros = (contenido: any, i:number, busqueda:boolean) => {
		return <Fichero
			view={"grid"}
			key={"ficheroItem__"+i}
			contenido={{...contenido, imagen: getImagen(contenido)}}
			action={(e) => {e.stopPropagation(); handleClickFichero(contenido)}}
			doubleClick={() => handleDoubleClickFichero(contenido)}
			disabled={!!contenido.inactivo}
			rightAction={rightClickAction}
			busqueda={busqueda}/>
	};
	const handleDoubleClickFichero = (contenido:any) => {
		if(contenido.files || contenido.realDirectory){
			let content : Directorio = contenido.files ? contenido : contenido.realDirectory;
			navigateTo(content._id as string, content.name,0,content.foldersPath as FoldersPath[], content.instructions as Instrucciones[]);
			changeAppHistory({type:'pushHistory', element:{_id:content._id as string, name:content.name, foldersPath:content.foldersPath as FoldersPath[], instructions:content.instructions as Instrucciones[]}});
		}else{
			downloadFile(contenido.url, contenido.name)
		}
	};

	const handleClickFichero = (element:(FicheroType | Directorio | EnlaceDirecto)) => {
		let itsReadOnly = !!user?.readOnly?.length && user.readOnly.findIndex(dir => dir === element?._id || dir === actualLevel?._id) !== -1;
    if(itsReadOnly){
      return;
    }
		if(actualLevel?._id && !(element as Directorio).blocked){
			let newElements = [...portaPapeles.elements];
			let findIndex = newElements.findIndex(elem => elem._id === element._id);
			if(findIndex !== -1){
				newElements.splice(findIndex, 1);
			}else{
				newElements.push(element);
			}
			changePortapapeles({...portaPapeles, elements:newElements});
		}
	}

	const handleDownloadZip = () => {
        downloadFolderAsZip(elementActive._id).then(response => {
            let blob = new Blob([response.data], {type:"application/zip"});
            let link = document.createElement('a');
            link.href=window.URL.createObjectURL(blob);
            link.download = elementActive.name + ".zip";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }).catch(err => console.error(err));
    }

	const handleDropOnLevel = (e: React.DragEvent<HTMLDivElement>) => {
		//Upload path es un array de strings de los ids hasta la carpeta donde nos encontramos por que esto es cuando estamos arrastrando en el nivel.
		let idsPath =  (actualLevel?.foldersPath as any[]).map(item => item._id).concat( (actualLevel?._id as string) );
		let namesPath =   (actualLevel?.foldersPath as any[]).map(item => item.name).concat( (actualLevel?.name as string) ).join('/') + '/';
		handleDrop(e, (actualLevel?._id as string),  (actualLevel?.name as string), idsPath, namesPath);
	};

	const generateEnlace = () => {
		let directorios = elementActive.files ? [elementActive._id] : [];
		let ficheros : string[] = elementActive.files ? [] : [elementActive._id];

		generateEnlaceToDownload(directorios, ficheros).then(response => {
			setEnlaceToDownload(response.data.enlace);
			setModalEnlace(true);
			setOpenLevelDropdown(false);
		}).catch(err => {
			console.error(err);
		})
	};

	const handleCreateDirectAccess = () => {
		createAccesoDirecto(elementActive._id as string,elementActive.name + '_AccesoDirecto',actualLevel?._id as string).then(response => {
			addContent([response.data.enlaceDirecto]);
			setOpenDropdown(false);
		}).catch(err => console.error(err));
	}

	const handleBlockFolder = () => {
		let blockPromise = new Promise<Directorio>((resolve, reject) => {
			if(elementActive.blocked){
				unBlockFolder(elementActive._id as string).then(response => resolve(response.data.directorio)).catch(err => reject(err));
			}else{
				blockFolder(elementActive._id as string).then(response => resolve(response.data.directorio)).catch(err => reject(err));
			}
		});

		blockPromise.then((directorio : Directorio) => {
			setOpenDropdown(false);
			let newContent = [...actualLevel?.contenido as (Directorio | FicheroType | EnlaceDirecto)[]];
			let index = newContent.findIndex(element => element._id === directorio._id);
			if(index !== -1){
				newContent[index] = directorio;
				changeLevel({...(actualLevel as {_id:string, contenido:any[], name:string}), contenido:newContent});
			}
			changePortapapeles({...portaPapeles, elements:[]});
		}).catch(err => console.error(err));
	}

	return (
		<div className={props.displayMode === "grid" ? "gridFicheros" : "tablaFicheros"}
			 onDragOver={(e) => e.preventDefault()}
			 onDrop={(e) => actualLevel?._id ? handleDropOnLevel(e) : {}}
			 onDragEnter={(e)=> actualLevel?._id ? handleDrag(e, actualLevel?.name as string) : {}}
			 onDragLeave={actualLevel?._id ? handleDragLeave : ()=>{}}
			//  onClick={()=>changePortapapeles({action:null, elements:[]})}
			 onContextMenu={actualLevel?._id ? handleOpenLevelMenu : ()=>{}}>

			{props.displayMode === 'grid' ?
					<React.Fragment>
						{searchContent ? (searchContent as any[]).map((contenido, i) => {
							return renderFicheros(contenido, i, true);
						}) : actualLevel?.contenido.map((contenido, i) => {
							return renderFicheros(contenido, i, false)
						})}
					</React.Fragment>
				:
					<React.Fragment>{
						searchContent ? <TablaFicheros contenido={searchContent} rightAction={rightClickAction} clickAction={handleClickFichero} busqueda={true} doubleClick={handleDoubleClickFichero}/>
						:
						<TablaFicheros contenido={actualLevel?.contenido as any[]} rightAction={rightClickAction} clickAction={handleClickFichero} doubleClick={handleDoubleClickFichero}/>
					}
					</React.Fragment>
			}

			{openDropdown &&
				<Dropdown open={openDropdown} setOpen={setOpenDropdown} elementRef={fileRef.current} direction={"bottom"} background={false} id={"fileMenu__dropdown"}>
					{portaPapeles.elements.length < 2 &&<DropDownOption value={"Información"} icon={infoIcon} action={()=>setModalInfo(true)}/>}
					{actualLevel?._id !== "" && portaPapeles.elements.length < 2 && !(!!user?.readOnly?.length && user.readOnly.findIndex(dir => dir === actualLevel?._id || dir === elementActive._id) !== -1) && <DropDownOption value={"Renombrar"} icon={renameIcon} action={()=>setModalRename(true)}/>}
					{actualLevel?._id !== "" && portaPapeles.elements.length > 0 && !elementActive.realDirectory && <DropDownOption value={"Copiar"} icon={copyIcon} action={handleCopyElement}/>}
					{actualLevel?._id !== "" && portaPapeles.elements.length > 0 && <DropDownOption value={"Cortar"} icon={moveIcon} action={handleCutElement}/>}
					{actualLevel?._id !== "" && portaPapeles.elements.length > 0 && (!elementActive.files || !elementActive.blocked) &&  <DropDownOption value={"Eliminar"} icon={deleteIcon} action={()=>setModalDelete(true)}/>}
					{actualLevel?._id !== "" && portaPapeles.elements.length < 2 && !elementActive.realDirectory && <DropDownOption value={"Generar Enlace"} icon={enlaceIcon} action={generateEnlace}/>}
					{actualLevel?._id !== "" && portaPapeles.elements.length < 2 && elementActive.files && <DropDownOption value={"Descargar"} icon={downloadIcon} action={handleDownloadZip}/>}
					{actualLevel?._id !== "" && portaPapeles.elements.length < 2 && elementActive.files && !(!!user?.readOnly?.length && user.readOnly.findIndex(dir => dir === actualLevel?._id || dir === elementActive._id) !== -1) && <DropDownOption value={"Crear acceso directo"} icon={accesoDirectoIcon} action={handleCreateDirectAccess}/>}
					{user?.admin && actualLevel?._id !== "" && portaPapeles.elements.length < 2 && elementActive.files && <DropDownOption value={elementActive.blocked ? "Desbloquear carpeta" : "Bloquear carpeta"} icon={elementActive.blocked ? unBlockIcon : blockIcon} action={handleBlockFolder}/>}
				</Dropdown>
			}

			{
				openLevelDropdown && <Dropdown open={openLevelDropdown} setOpen={setOpenLevelDropdown} coordinates={rightClickPosition}>
					<DropDownOption value={"Pegar"} icon={pasteIcon} action={handlePasteElement} disabled={portaPapeles.elements.length < 1} />
				</Dropdown>
			}
			{modalRename && elementActive &&  <ModalRename open={modalRename} setOpen={setModalRename} elementoToRename={elementActive}/>}
			{modalDelete && elementActive && <ModalDelete open={modalDelete} setOpen={setModalDelete} elementToDelete={elementActive}/>}
			{modalInfo && elementActive && <ModalInfo open={modalInfo} setOpen={setModalInfo} element={elementActive}/>}
			{modalEnlace && elementActive && enlaceToDownload && <ModalEnlace open={modalEnlace} setOpen={setModalEnlace} enlace={enlaceToDownload}/>}

			{ portaPapeles.elements.length > 0 && <div className="elementsSelectedInfo">
					<span>{portaPapeles.elements.length} elementos seleccionados</span>
					<FontAwesomeIcon icon={faTimes} onClick={() => changePortapapeles({action:null, elements:[]})}/>
					</div>}
		</div>
	);
};

export default Nivel;
