import React, {ChangeEvent, Dispatch, FormEvent, FunctionComponent, useContext, useState} from 'react';
import Modal from "../Modal/Modal";
import {FileSystemContext} from "../../utils/FileSystemContext";
import {renameAccesoDirecto, renameFile, renameFolder} from "../../api/fileSystem";

type Props = {
    open:boolean,
    setOpen:Dispatch<boolean>,
    elementoToRename:any
}

const ModalRename : FunctionComponent<Props> = (props) => {
    let rexForExtension = /(?:\.([^.]+))?$/;
    let extension = rexForExtension.exec(props.elementoToRename.name);

    const { actualLevel, changeLevel } = useContext(FileSystemContext);
    const [newName, setNewName] = useState(!props.elementoToRename.files && extension && extension[0] ? props.elementoToRename.name.split(extension[0])[0] : props.elementoToRename.name);

    const handleRenameElement = (e : FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        let nameToRename = !props.elementoToRename.files && extension && extension[0] ? newName.concat(extension[0]) : newName;
        let promiseRename = new Promise((resolve, reject) => {
            if(props.elementoToRename.files){
                //Si es una carpeta ejecuto la funcion de renombrar carpeta
                renameFolder(props.elementoToRename._id,nameToRename).then(response => {
                    resolve(response.data.directorio)
                }).catch(err => reject(err))
            }
            if(!props.elementoToRename.files && !props.elementoToRename.realDirectory){
                //Si es un fichero ejecuto la funcion de renombrar un fichero.
                renameFile(props.elementoToRename._id, nameToRename).then(response => {
                    resolve(response.data.fichero)
                }).catch(err => reject(err));
            }
            if(props.elementoToRename.realDirectory){
                renameAccesoDirecto(props.elementoToRename._id, nameToRename).then(response => {
                    resolve(response.data.enlaceDirecto);
                }).catch(err => reject(err));
            }
        });
        promiseRename.then((newElement : any) => {
            //Encuentro el elemento en el contenido actual.
            let elementIndex : number = actualLevel?.contenido.findIndex(element => element._id === newElement._id) as number;
            //Reemplazo el elemento por el nuevo y actualizo el contenido.
            let newContent = [...actualLevel?.contenido as any[]];
            newContent[elementIndex] = newElement;

            changeLevel({...(actualLevel as {_id:string, contenido:any[], name:string}), contenido:newContent});

            //Cierro el modal
            props.setOpen(false);
        }).catch(err => console.error(err));
    };

    const handleNameChange = (e : ChangeEvent<HTMLInputElement>) =>{
        setNewName(e.target.value);
    }

    return <Modal open={props.open} setOpen={props.setOpen}>
        <form onSubmit={handleRenameElement}>
            <div className={"addFolderModal"}>
                <div className={"modalTitle"}>
                    <span> Renombrar {props.elementoToRename.files ? "carpeta" : "fichero" }</span>
                </div>
                <input type="text" placeholder="Nombre de la carpeta..." value={newName} onChange={handleNameChange} />
                <div className={"modalBtns_container"}>
                    <button type={"button"} className={"modalBtn"} style={{backgroundColor:"#9B9B9B"}} onClick={()=> props.setOpen(false)}>Cancelar</button>
                    <button type={"submit"} className={"modalBtn"} style={{backgroundColor:"var(--main-color)"}}>Renombrar</button>
                </div>
            </div>
        </form>
    </Modal>
};

export default ModalRename;
