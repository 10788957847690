import React, { FunctionComponent } from 'react';
import inputStyles from './Checkbox.module.css';

type InputType = 'radio' | 'checkbox';

type Props = {
    type: InputType,
    checked?: boolean,
    name: string,
    label: string,
    superLabel?: string,
    rounded?: boolean,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    disabled?: boolean
    image?:string
}

const Checkbox: FunctionComponent<Props> = ({ type, checked, name, label, superLabel, rounded, onChange, disabled = false, image }) => {

    return (
        <label className={`${inputStyles.checkContainer} ${disabled ? inputStyles.disabled : ''}`} style={{opacity:!checked ? "0.7" : "1"}}>
            {superLabel && <span>{superLabel}</span>}
            {image && <img src={image} style={{margin:'0 12px 0 8px'}}/>}
            <span>{label}</span>
            <input disabled={disabled} type={type} checked={checked} name={name} onChange={onChange} />
            <span className={`${inputStyles.checkmark} ${(type === 'radio' || rounded) ? inputStyles.rounded : ''}`}></span>
        </label>
    )
}

export default Checkbox;
