import React, { useContext } from "react";
import {FileSystemContext} from "./FileSystemContext";
import useFilesActions from "./useFilesActions";
import {Directorio, FicheroType, FoldersPath} from "./Types";
import { NotificationContext } from "./NotificationContext";
import { UserContext } from "./UserContext";

function useDragNDrop(){

    const {uploadFile, createNewFolder, addContent} = useFilesActions();

    const { user } = useContext(UserContext);
    const { actualLevel, updateUploadProgress} = useContext(FileSystemContext);
    const {setType, setText, setActive} = useContext(NotificationContext);

    const handleDrag = (e: React.DragEvent<HTMLElement>, nameFolderToUpload:string) => {
        e.preventDefault();
        e.stopPropagation();
        let itsReadOnly = !!user?.readOnly?.length && user.readOnly.findIndex(dir => dir === actualLevel?._id) !== -1;
        if(itsReadOnly){
            console.log('readOnly')
            return;
        }
        let elementFocused =  (e.currentTarget as HTMLElement);
        elementFocused.classList.add("isDragging");
        elementFocused.dataset.name = nameFolderToUpload;
    };

    const uploadSingleFilePromise = (file:File, folderDst:string, nameFolderDst:string, updateContent:boolean, newFoldersPath?:string[], newNamesPath?:string) : Promise<FicheroType> => {
        return new Promise<FicheroType>((resolve, reject) => {
            uploadFile(file, folderDst,nameFolderDst,newFoldersPath,newNamesPath ).then(response => {
                if(updateContent)
                    addContent([response]);
                resolve(response);
            }).catch(err => reject(err));
        })
    };

    const uploadDirectoryWithFiles = (folderName:string, folderToUpload:string, nameFolderToUpload:string, newFolderPath:string[],updateContent:boolean, files:File[], namesPath?:string) : Promise<Directorio> => {
        return new Promise<Directorio>((resolve, reject) => {
           createNewFolder(folderName, "", folderToUpload, newFolderPath).then((directorio:Directorio) => {
               let uploadFilesPromises : Promise<FicheroType>[] = [];
               newFolderPath.push(directorio._id as string);
               namesPath = namesPath ? namesPath.concat(directorio.name + '/') : undefined;
               //Progreso de subida de ficheros
               updateUploadProgress({type:'addFiles', files:files.length});
               //Subida de ficheros
               files.forEach((file:File) => {
                   uploadFilesPromises.push(uploadSingleFilePromise(file, directorio._id as string, directorio.name, false, newFolderPath, namesPath));
               });
               Promise.all(uploadFilesPromises).then(response => {
                   //Se han subido todos los ficheros.
                   if(updateContent)
                       addContent([directorio]);
                   //Resuelvo la funcion con el directorio en cuestión.
                   resolve(directorio);
               }).catch(err => reject(err));
           })
        });
    };

    const uploadDirectoryRecursive =  (directorio:any, folderToUpload:string, nameFolderToUpload:string, newFolderPath:string[], updateContent:boolean, namesPath?:string) : Promise<Directorio> => {
        return new Promise<Directorio>((resolve, reject) => {
           //Voy a subir el directorio y sus ficheros. Cuando me encuentro un directorio hago lo mismo, cuando acabe de recorrer el arbol entero, resuelvo la promesa;
           let uploadAllsubItemsPromises : Promise<any>[] = [];
           let subDirectories : any[] = [];
           let dirReader = directorio.createReader();
           dirReader.readEntries((items : any) => {
               let processFilesPromises : Promise<File>[] = [];
               for(let i=0;i<items.length;i++){
                   //Si el subItem es un fichero, lo subo, si es un directorio, lo recorro y voy subiendo sus ficheros.
                   if(items[i].isFile){
                       let promiseProcessFile = new Promise<File>((resolve, reject) => {
                           items[i].file((file:File) => resolve(file));
                       });

                       processFilesPromises.push(promiseProcessFile);
                   }
                   if(items[i].isDirectory){
                       subDirectories.push(items[i]);
                   }
               }
               //Tengo un array con los subDirectorios para subirlos ahora una vez ya tenga creado el directorio y subidos todos sus ficheros.
               Promise.all(processFilesPromises).then(files => {
                   uploadDirectoryWithFiles(directorio.name, folderToUpload, nameFolderToUpload, newFolderPath, updateContent, files, namesPath).then(directorio => {
                       subDirectories.forEach(subDirectorio => {
                           let newFolderPath = (directorio.foldersPath as FoldersPath[]).map((item:any) => item._id);
                           newFolderPath.push(directorio._id);
                           uploadAllsubItemsPromises.push(uploadDirectoryRecursive(subDirectorio, directorio._id as string, directorio.name, newFolderPath, false, namesPath));
                       });
                       Promise.all(uploadAllsubItemsPromises).then(responses => {
                           resolve(directorio);
                       })
                   }).catch(err => reject(err));
               })
           })
        });
    };

    const handleDrop = (e: React.DragEvent<HTMLDivElement>, folderToUpload: string, nameFolderToUpload:string, folderToUploadPath:string[], namesPath?:string) => {
        e.stopPropagation();
        e.preventDefault();
        let itsReadOnly = !!user?.readOnly?.length && user.readOnly.findIndex(dir => dir === actualLevel?._id) !== -1;
        if(itsReadOnly){
            return;
        }
        let elementFocused =  (e.currentTarget as HTMLElement);
        elementFocused.classList.remove("isDragging");
        if(elementFocused.classList.contains("gridFicheros") || elementFocused.classList.contains("tablaFicheros") || elementFocused.classList.contains("folderItem") ){
            let uploadAllContentPromises : Promise<FicheroType | Directorio>[] = [];
            if(!!DataTransferItem.prototype.webkitGetAsEntry){
                //Comprobamos si el navegador soporta estas funciones para subir carpeta.
                let contentToUpload : any | undefined = e.nativeEvent?.dataTransfer?.items;
                if(contentToUpload){
                    //Si tengo contenido para subir, vamos a analizar uno por uno este contenido.
                    for(let i=0;i<contentToUpload.length;i++){
                        let itemToUpload = contentToUpload[i].webkitGetAsEntry();
                        if(itemToUpload.isFile && itemToUpload.file.type !== ""){
                            //Hay que procesar el fichero
                            let processAndUploadFilePromise = new Promise<FicheroType>((resolve,reject) => {
                                //Progreso de subida de ficheros
                                updateUploadProgress({type:'addFiles', files:1});
                
                                itemToUpload.file((file: File) => {
                                    uploadSingleFilePromise(file, folderToUpload, nameFolderToUpload, false,folderToUploadPath, namesPath).then(file => {
                                        resolve(file);
                                    }).catch(err => reject(err));
                                })
                            });
                            uploadAllContentPromises.push(processAndUploadFilePromise);
                        }
                        if(itemToUpload.isDirectory){
                            //Hay que procesar el directorio.
                            uploadAllContentPromises.push(uploadDirectoryRecursive(itemToUpload, folderToUpload, nameFolderToUpload, folderToUploadPath, actualLevel?._id === folderToUpload, namesPath));
                        }
                    }

                }
            }else{
                //Si el navegador no soporta lo de las carpetas, subo los ficheros tal cual.
                let files = e.nativeEvent?.dataTransfer?.files;
                //Progreso de subida de ficheros
                files?.length && updateUploadProgress({type:'addFiles', files:files.length});;

                //Subida de ficheros.
                for (let i = 0; i < (files ? files.length : 0); i++) {
                    if (files && files[i].type !== "") {
                        uploadAllContentPromises.push(uploadSingleFilePromise(files[i], folderToUpload, nameFolderToUpload, true, folderToUploadPath, namesPath));
                    }
                }
            }
            Promise.all(uploadAllContentPromises).then(ficheros => {
                setType('success');
                setText("Se han subido correctamente los ficheros.");
                setActive(true);
                if(actualLevel?._id === folderToUpload){
                    addContent(ficheros);
                }
            }).catch(err => {
                console.error(err);
                setType('error');
                setText("Ha ocurrido algún error en el servidor.");
                setActive(true);
            });      
        }
    };

    const handleDragLeave = (e: React.DragEvent<HTMLElement>) => {
        e.stopPropagation();
        e.preventDefault();
        let elementFocused =  (e.currentTarget as HTMLElement);
        elementFocused.classList.remove("isDragging");
    };

    return {handleDrag, handleDrop, handleDragLeave}
};

export default useDragNDrop;
