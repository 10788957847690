import React, {FunctionComponent, useContext} from 'react';
import styles from './TablaFicheros.module.css';

import {FilterContext} from "../../../utils/FiltersContext";
import Fichero from '../Fichero';
import {FicheroType, Directorio, EnlaceDirecto} from '../../../utils/Types';
type Props = {
	contenido:(FicheroType | Directorio | EnlaceDirecto)[];
	rightAction:(e: any, element:FicheroType | Directorio | EnlaceDirecto)=>void,
	clickAction:(contenido:FicheroType | Directorio | EnlaceDirecto) => void,
	busqueda?:boolean,
	doubleClick:(contenido:FicheroType | Directorio | EnlaceDirecto) => void
	showDeleted?:boolean
};

const TablaFicheros: FunctionComponent<Props> = (props) => {
	const {orderBy, changeOrderBy} = useContext(FilterContext);

	const handleChangeOrder = (attr:"name"|"creation_date"|"updated_date"|"size"|"deleted_date") => {
		if(attr === orderBy.attr){
			//Cambiar solo la dirección
			let newDirection : "asc" | "desc" = orderBy.direction === "asc" ? "desc" : "asc";
			changeOrderBy({...orderBy, direction:newDirection});
		}else{
			//Cambiar el attributo
			changeOrderBy({...orderBy, attr:attr});
		}
	};


	return (
		<React.Fragment>
			<table className={styles.table}>
				<thead>
				<tr>
					<th className={`${orderBy.attr === "name" ? "orderedBy" : ""} ${orderBy.direction === "desc" ? "down" : "up"}`}
						onClick={()=>handleChangeOrder("name")} >
						Nombre
					</th>
					<th className={`${orderBy.attr === "creation_date" ? "orderedBy" : ""} ${orderBy.direction === "desc" ? "down" : "up"}`}
						onClick={()=>handleChangeOrder("creation_date")}>
						Fecha de creación
					</th>
					<th className={`${orderBy.attr === "updated_date" ? "orderedBy" : ""} ${orderBy.direction === "desc" ? "down" : "up"}`}
						onClick={()=>handleChangeOrder("updated_date")}>
						Fecha de modificación
					</th>
					{props.showDeleted && <th className={`${orderBy.attr === "deleted_date" ? "orderedBy" : ""} ${orderBy.direction === "desc" ? "down" : "up"}`}
						onClick={()=>handleChangeOrder("deleted_date")}>
							Fecha de eliminación
						</th>}
					<th className={`${orderBy.attr === "size" ? "orderedBy" : ""} ${orderBy.direction === "desc" ? "down" : "up"}`}
						onClick={()=>handleChangeOrder("size")}>
						Tamaño
					</th>
				</tr>
				</thead>
				<tbody>
					{props.contenido && (props.contenido as any[]).map((contenido, i) => {
						return <Fichero key={"ficheroContent__"+i} disabled={!!contenido.inactivo} contenido={contenido} action={(e:any) =>{e.stopPropagation();  props.clickAction(contenido)}} view={"table"} rightAction={props.rightAction} busqueda={props.busqueda || false} doubleClick={() => props.doubleClick(contenido)} showDeleted={props.showDeleted}/>
					})}
				</tbody>
			</table>



		</React.Fragment>
	);
};

export default TablaFicheros;
