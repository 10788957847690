import React from 'react';
import logoSevillaBlanco from '../../assets/logo-sevilla-blanco.svg';
import './loadingScreen.css';

function loadingScreen() {
	return (
		<div className="loading-screen">
			<img className="fading-effect" src={logoSevillaBlanco} alt="logo" />
		</div>
	);
}

export default loadingScreen;
