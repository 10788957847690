import React, {Dispatch, FunctionComponent, useContext } from 'react';
import DropDownOption from "../Dropdown/DropdownOption";
import Checkbox from "../CheckBox/Checkbox";
import PDFDoc from "../../assets/documentTypes/pdfDoc.svg";
import WordDoc from "../../assets/documentTypes/wordDoc.svg";
import ExcelDoc from "../../assets/documentTypes/excelDoc.svg";
import FolderIcon from "../../assets/folderBold.svg";
import OtherDoc from "../../assets/documentTypes/otherDoc.svg";
import Dropdown from "../Dropdown/Dropdown";
import {FilterContext} from "../../utils/FiltersContext";
import {FileSystemContext} from "../../utils/FileSystemContext";
import { SearchContext } from '../../utils/SearchContext';

type Props = {
    open:boolean,
    setopen:Dispatch<boolean>,
    btnRef:HTMLElement
}
const Filters : FunctionComponent<Props> = (props) => {
    const {filters, changeFilters} = useContext(FilterContext);
    const {actualLevel} = useContext(FileSystemContext);
    const {searchContent} = useContext(SearchContext);

    //HANDLE FILTERS
    const handleChangeFilters = (property: string, value: boolean) => {
        changeFilters({...filters, [property]: value});
    };

    return <Dropdown
        open={props.open}
        setOpen={props.setopen}
        direction={'bottom-end'}
        elementRef={props.btnRef}
    >
        <DropDownOption className={'noBottomBorder extendRight'} value={''}>
            <Checkbox
                disabled={!actualLevel?._id && !searchContent}
                type={'checkbox'}
                name={'filterPdf'}
                checked={filters.pdf}
                label={"PDF's"}
                image={PDFDoc}
                onChange={() => handleChangeFilters('pdf', !filters.pdf)}
            />
        </DropDownOption>
        <DropDownOption className={'noBottomBorder extendRight'} value={''}>
            <Checkbox
                disabled={!actualLevel?._id && !searchContent}
                type={'checkbox'}
                checked={filters.doc}
                name={'filterDoc'}
                label={'Documentos'}
                image={WordDoc}
                onChange={() => handleChangeFilters('doc', !filters.doc)}
            />
        </DropDownOption>
        <DropDownOption className={'noBottomBorder extendRight'} value={''}>
            <Checkbox
                disabled={!actualLevel?._id && !searchContent}
                type={'checkbox'}
                checked={filters.excel}
                name={'filterExcel'}
                label={'Excels'}
                image={ExcelDoc}
                onChange={() => handleChangeFilters('excel', !filters.excel)}
            />
        </DropDownOption>
        <DropDownOption className={'noBottomBorder extendRight'} value={''}>
            <Checkbox
                disabled={!actualLevel?._id && !searchContent}
                type={'checkbox'}
                name={'filterFolder'}
                checked={filters.folders}
                label={'Carpetas'}
                image={FolderIcon}
                onChange={() => handleChangeFilters('folders', !filters.folders)}
            />
        </DropDownOption>
        <DropDownOption className={'noBottomBorder extendRight'} value={''}>
            <Checkbox
                disabled={!actualLevel?._id && !searchContent}
                type={'checkbox'}
                name={'filterOther'}
                checked={filters.other}
                label={'Otros'}
                image={OtherDoc}
                onChange={() => handleChangeFilters('other', !filters.other)}
            />
        </DropDownOption>
    </Dropdown>
};

export default Filters;
