import React, { useEffect, useState} from 'react';
import AddUserModal from "./AddUserModal";
import {getAllUsersGroupedByIntegral} from "../../api/users";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCog, faTrashAlt, faUserPlus} from "@fortawesome/free-solid-svg-icons";
import styles from './GestionarUsers.module.css';
import {Usuario} from "../../utils/Types";
import DeleteUserModal from "./DeleteUserModal";
import Header from "../Header/Header";
import Button from '../Button/Button';
import { getAllFoldersStructure } from '../../api/fileSystem';

function GestionarUsers(){
    const [openAddUser, setOpenAddUser] = useState<boolean>(false);
    const [editingUser, setEditingUser] = useState<boolean>(false);
    const [openDeleteUser, setOpenDeleteUser] = useState<boolean>(false);

    const [users, setUsers] = useState<Usuario[]>([]);
    const [userActive, setUserActive] = useState<Usuario>({name:"", surname:"", dni:"", password:""});
    const [recargar, setRecargar] = useState(false);

    const [folderStructure, setFolderStructure] = useState([]);

    useEffect(() => {
        getAllFoldersStructure().then(response => {
            setFolderStructure(response.data);
        }).catch(err => console.error(err));
     }, []);

    useEffect(()=>{
        //Load usuarios
        getAllUsersGroupedByIntegral().then(response => {
            if(response.status === 200){
                setUsers(response.data.usuarios);
            }
        }).catch(err => console.error(err));
    },[recargar]);

    //Funcion para cuando se cierran los modals poner el usuario activo en su forma inicial y poner a falso la variable de estado que indica si se está editando un usuario.
    useEffect(()=>{
        if(!openAddUser  && !openDeleteUser){
            setUserActive({name:"", surname:"", dni:"", password:""});
            setEditingUser(false);
        }
    },[openAddUser,openDeleteUser]);

    /*--- Funciones para abrir los modals y activar el usuario sobre el que se va operar ----*/
    const handleAddUser = (integral?:string) => {
        if(integral)
            setUserActive({...userActive, integral});
        setOpenAddUser(true);
    };

    const handleDeleteUser = (user:any) => {
        setUserActive(user);
        setOpenDeleteUser(true);
    };

    const handleEditUser = (user:any, integral:string, permisos:any[], readOnly: any[]) => {
        setOpenAddUser(true);
        setUserActive({...user, integral, permisos, readOnly});
        setEditingUser(true);
    };

    return <React.Fragment>
        {/*--- Cabecera de la app. (Buyscador, logo, infoUsuario) ---*/}
        <Header />
        <div className={styles.mainContainer}>
            <div className={styles.btnHeader}>
                <Button contenido={"Añadir usuario"} action={() => handleAddUser()} icon={faUserPlus}/>
            </div>
            <div className={styles.container}>
                {users.map((integralWithUsers:any, i:number) => {
                    return <div className={styles.integralContainer} key={"integralContainer__"+i}>
                        <div className={styles.headerRow}>
                            <div className={styles.integralTitle}>
                                {integralWithUsers._id ? integralWithUsers._id : "Administradores"}
                            </div>
                            <div className={styles.iconBtn} onClick={()=>handleAddUser(integralWithUsers._id)}>
                                <FontAwesomeIcon icon={faUserPlus} color={"#9099A0"}/>
                            </div>
                        </div>
                        <div className={styles.usersTable}>
                            {integralWithUsers.users.map((user:any, i:number) => {
                                return <div className={styles.userRow} key={"userRow__"+i}>
                                    <div>
                                        <span>{user.name} </span>
                                        <span>{user.surname}</span>
                                    </div>
                                    <span>{user.dni}</span>
                                    <div style={{textAlign:'right'}}>
                                        <FontAwesomeIcon icon={faCog} style={{cursor:'pointer', fill:"none"}} color={"var(--main-color)"} onClick={()=>handleEditUser(user, integralWithUsers._id, user.permisos, user.readOnly)}/>
                                        <FontAwesomeIcon icon={faTrashAlt} color={"#f44336"} style={{marginLeft:'8px',cursor:'pointer'}} onClick={()=>handleDeleteUser(user)}/>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                })}
            </div>

            {openAddUser && <AddUserModal open={openAddUser} setOpen={setOpenAddUser} user={userActive} setUser={setUserActive} editing={editingUser} recargar={recargar} setRecargar={setRecargar} foldersStructure={folderStructure}/>}
            {openDeleteUser && <DeleteUserModal open={openDeleteUser} setOpen={setOpenDeleteUser} user={{_id:userActive._id as string, name:userActive.name}} recargar={recargar} setRecargar={setRecargar} />}
        </div>
    </React.Fragment>
}
export default GestionarUsers;
