import React, {Dispatch, FormEvent, FunctionComponent, useState} from 'react';
import Modal from "../Modal/Modal";
import userIcon from "../../assets/user.svg";
import {Usuario} from "../../utils/Types";
import {createUser, editUser, updatePermissions, updateReadOnly} from "../../api/users";
import Button from '../Button/Button';

import TreeDirectory from '../TreeDirectorys/TreeDirectorys';
type Props = {
    open:boolean,
    setOpen:Dispatch<boolean>,
    user:Usuario,
    setUser:Dispatch<Usuario>,
    editing:boolean,
    recargar:boolean,
    setRecargar:Dispatch<boolean>,
    foldersStructure:any[]
}
const AddUserModal : FunctionComponent<Props> = (props) => {

    let initialSelections = props.user.permisos ? props.user.permisos.reduce((old, folderId) => ({...old, [folderId]:{} }), {}) : {};
    let initialReadOnly = props.user.readOnly ? props.user.readOnly.reduce((old, folderId) => ({...old, [folderId]: {}}), {}) : {};

    console.log(props.user);
    
    const [contentModal, setContentModal] = useState(0);
    const [foldersSelected, setFoldersSelected]  = useState<{[key:string]: {}}>(initialSelections);
    const [foldersOnlyRead, setFoldersOnlyRead] = useState<{[key: string] : {}}>(initialReadOnly);

    const handleChange = (value:string, prop:string) => {
        props.setUser({...props.user, [prop]:value});
    };

    const handleCreateUser = (e:FormEvent) => {
        e.preventDefault();
        let prAction = new Promise((resolve, reject) => {
            if(props.editing){
                editUser(props.user).then(() => {
                    resolve(true);
                }).catch(err => reject(err));
            }else{
                createUser(props.user).then(() => {
                    resolve(true)
                }).catch(err =>reject(err));
            }
        });

        prAction.then(()=>{
            props.setRecargar(!props.recargar);
            props.setOpen(false);
        });

    };


    const handleSavePermisos = async () => {
        if(props.user._id){
            let ids = Object.keys(foldersSelected);
            let readOnlyIds = Object.keys(foldersOnlyRead);
            await updatePermissions(props.user._id, ids);
            await updateReadOnly(props.user._id as string, readOnlyIds);
            props.setRecargar(!props.recargar);
            props.setOpen(false);
        }
    }
    return <Modal open={props.open} setOpen={props.setOpen}>
            <div className={'addFolderModal'}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent:'space-between' }}>
                    <div style={{display:'inherit'}}>
                        <img src={userIcon} alt='user icon' style={{ marginRight: '12px' }} />
                        <span>{contentModal === 0 ? (props.user._id ? props.user.name : "Nuevo Usuario" ) : "Permisos"}</span>
                    </div>
                    { props.user._id && <Button contenido={contentModal === 0 ? "Permisos" : "Datos"} action={() => contentModal === 0 ? setContentModal(1) : setContentModal(0)}/>}
                </div>
                {contentModal === 0 && 
                <form onSubmit={handleCreateUser} style={{display:'grid', gap:'15px'}}>
                    <input type='text' placeholder='Nombre.' value={props.user.name} onChange={(e) => handleChange(e.target.value, "name")} />
                        <input type='text' placeholder='Apellidos' value={props.user.surname} onChange={(e) => handleChange(e.target.value, "surname")} />
                        <input type='text' placeholder='DNI' value={props.user.dni} onChange={(e) => handleChange(e.target.value, "dni")} />
                        <input type='text' placeholder='Integral' value={props.user.integral ? props.user.integral : ""} onChange={(e) => handleChange(e.target.value, "integral")} />
                        {!props.editing && <React.Fragment>
                            <input type={"password"} placeholder={"Contraseña"} value={props.user.password} onChange={(e) => handleChange(e.target.value, "password")}/>
                            <input type={"password"} placeholder={"Repetir Contraseña"} value={props.user.repeatPassword || ""} onChange={(e) => handleChange(e.target.value, "repeatPassword")}/>
                        </React.Fragment>}
        
                        <div className={'modalBtns_container'}>
                            <button type={"button"} className={'modalBtn'} style={{ backgroundColor: '#9B9B9B' }} onClick={()=>props.setOpen(false)}>
                                Cancelar
                            </button>
                            <button type={"submit"} className={'modalBtn'} style={{ backgroundColor: 'var(--main-color)' }}>
                                {props.editing ? "Guardar" : "Crear"}
                            </button>
                        </div>    
                </form>    
             }
             { props.user._id && contentModal === 1 && props.foldersStructure.length > 0 && <React.Fragment>
                 <div style={{maxHeight:'450px', minWidth:'500px', backgroundColor:'rgba(44,62,80,.15)', padding:'12px', overflow:'auto'}}>
                    <TreeDirectory contenido={props.foldersStructure} selections={foldersSelected} setSelections={setFoldersSelected} readOnlySelections={foldersOnlyRead} setReadOnlySelections={setFoldersOnlyRead}/> 
                </div>
                <div>
                    <Button contenido={"Guardar"} backgroundColor={'var(--main-color)'} style={{color:'white'}} action={handleSavePermisos}/>
                </div>   
                </React.Fragment>
                }
        </div>
    </Modal>
};

export default AddUserModal;
