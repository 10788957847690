import React, { FunctionComponent, useState, useCallback, useMemo, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { FileSystemContext } from '../../utils/FileSystemContext';
import './Loader.css';
type Props = {
  instance: any,
};

const Loader: FunctionComponent<Props> = ({ instance }) => {
  const [counter, setCounter] = useState(0);
  const history = useHistory();
  const location = useLocation();
  const { uploadProgress } = useContext(FileSystemContext);

  const [percentUpload, setPercentUpload] = useState(0);

  const inc = useCallback(() => {
    setCounter((counter) => counter + 1);
  }, [setCounter]);
  const dec = useCallback(() => setTimeout(() => setCounter((counter) => counter - 1), 100), [setCounter]);

  const interceptors = useMemo(
    () => ({
      request: (config: any) => {
        inc();
        return config;
      },
      response: (response: any) => {
        dec();
        return response;
      },
      error: (error: any) => {
        dec();
        if (error.response?.status === 403 && !location.pathname.includes('downloadFiles')) history.push('/login');
        return Promise.reject(error);
      },
    }),
    [inc, dec]
  );

  useEffect(() => {
    const reqInterceptor = instance.interceptors.request.use(interceptors.request, interceptors.error);
    const resInterceptor = instance.interceptors.response.use(interceptors.response, interceptors.error);
    return () => {
      instance.interceptors.request.eject(reqInterceptor);
      instance.interceptors.response.eject(resInterceptor);
    };
  }, [interceptors]);

  useEffect(() => {
    let clientPercent = (uploadProgress.ficherosProcesados / uploadProgress.ficherosPorSubir) * 50;
    let serverPercent = (uploadProgress.ficherosSubidos / uploadProgress.ficherosPorSubir) * 50;
    setPercentUpload(clientPercent + serverPercent);
  }, [uploadProgress])

  if (counter > 0)
    return (
      <div className='loaderWraper'>
        <div className='loaderBck'>
          <div className='loaderContainer'>
            <svg className='circularLoader' viewBox='0 0 100 100'>
              <circle className='loaderPath' cx='50' cy='50' r='30' stroke='#e90c59' strokeWidth='5px' strokeLinecap='square' fill='none'>
                <animateTransform attributeName='transform' type='rotate' repeatCount='indefinite' dur='1.1s' values='0 50 50;180 50 50;720 50 50' keyTimes='0;0.5;1' />
                <animate attributeName='stroke-dasharray' repeatCount='indefinite' dur='1.1s' values='0 180 188;160 50 28;0 180 188' keyTimes='0;0.5;1' />
              </circle>
            </svg>
          </div>
          <div className="uploadInfo">
              {
                uploadProgress.ficherosPorSubir > 0 && <div className='uploadIndicatorContainer'>
                  <div className='uploadPercent' style={{width:`${percentUpload}%`, maxWidth:'100%'}}></div>
                </div>
              }
              <span>
                {
                  uploadProgress.ficherosPorSubir > 0 && (
                    uploadProgress.ficherosPorSubir !== uploadProgress.ficherosProcesados ? "Procesando ficheros..." 
                    : uploadProgress.ficherosPorSubir !== uploadProgress.ficherosSubidos ? "Subiendo ficheros...." 
                    : "Procesando información..."
                    )
                }
              </span>
          </div>
        </div>
      </div>
    );

  return <div />;
};

export default Loader;
