import React, { createContext, Dispatch, FunctionComponent, SetStateAction, useState, useEffect } from 'react';

type Context = {
    active:boolean,
    setActive:Dispatch<SetStateAction<boolean>>,
    text:string,
    setText:Dispatch<SetStateAction<string>>,
    type:"error" | "success",
    setType:Dispatch<SetStateAction<"error"|"success">>
}

export const NotificationContext = createContext<Context>({active:false, setActive:()=>{}, text:"", setText:()=>{}, type:"error", setType:()=>{}});

export const NotificationProvider : FunctionComponent = (props) => {
    const [active, setActive] = useState<boolean>(false);
    const [text, setText] = useState<string>("");
    const [type, setType] = useState<"error" | "success">("error");

    useEffect(() => {
        if(active){
            setTimeout(()=> {
                setActive(false);
            },1500);
        }
        return () => {}
    }, [active])
    
    return <NotificationContext.Provider value={{active, setActive, text, setText, type, setType}}>
        {props.children}
        </NotificationContext.Provider>
}