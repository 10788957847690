import { CancelToken } from "axios";
import {conservacionAPI} from "./config";
import { io } from "socket.io-client";
import { serverURL } from "./config";

const filesURL =  "/fichero";
const foldersURL = "/folder";

export const socket = io(serverURL, {withCredentials:true});


socket.disconnect();

socket.on('disconnect', (reason) => {
    console.log(reason);
});

//LLamadas a la api relacionadas con los ficheros.
export const createFile = (file: File, type:string, directory:string,name:string, size:number, path:string) => {
    let data = new FormData();
    data.append('file', file);
    return conservacionAPI.post(filesURL, data, {params:{ type, directorioId:directory,name,size,path}});
};
export const searchFiles = (cancelToken:CancelToken,searchText:string, from:number, numberFiles:number, orderBy:string, direction:string, pdf:boolean, doc:boolean, other:boolean, excel:boolean,folders:boolean ) => {
    return conservacionAPI.get(filesURL+"/search", {cancelToken:cancelToken, params:{searchText, from, numberFiles, orderBy, direction, pdf, doc, other, excel, folders}})
};
export const updateFolderPath = (idFichero:string, foldersPath:string[]) => conservacionAPI.put(filesURL + "/foldersPath", {idFichero, foldersPath});
export const renameFile = (idFichero:string, newName:string) => conservacionAPI.put(filesURL + "/name", {idFichero, newName});
export const softDeleteFile = (idFichero: string) => conservacionAPI.delete(filesURL + "/softDelete", { params: { idFichero } });
export const hardDeleteFile = (idFichero:string) => conservacionAPI.delete(filesURL+'/hardDelete',{params:{idFichero}});
export const moveFile = (idFichero:string, destinoId:string) => conservacionAPI.put(filesURL + "/move", {idFichero, destinoId});
export const copyFile =  (idFichero:string, destinoId:string) => conservacionAPI.put(filesURL + "/copy", {idFichero, destinoId});
export const updateFileState = (idFichero:string, state:string) => conservacionAPI.put(filesURL + '/state', {idFichero, state});
export const getAllDeletedFiles = () => conservacionAPI.get(filesURL+ '/deleted');
export const restaurarFile = (id:string) => conservacionAPI.put(filesURL+ '/restaurar', {idFichero:id});
export const hardDeleteAllFiles = () => conservacionAPI.delete(filesURL+ '/hardDeleteAll');

//Llamadas a la api relacionadas con los directorios y su contenido.
export const getFirstContent = () => conservacionAPI.get(foldersURL + '/firstContent');

export const getFolderContent = (orderBy:string, directorioId:string, direction:string, limit:number, from:number, pdf:boolean, doc:boolean, other:boolean, excel:boolean, folders:boolean, sinProcesar:boolean, pendiente:boolean, aceptado:boolean, rechazado:boolean) => {
    return conservacionAPI.get(foldersURL + '/findByFilters', {params:{orderBy, directorioId, direction, limit, from,pdf,doc,other,excel,folders, sinProcesar, pendiente, aceptado, rechazado }});
};

export const createFolder = (name:string, instructions:string, parentId:string, foldersPath:string[]) => conservacionAPI.post(foldersURL, {name:name,instructions:instructions,  parent:parentId, foldersPath:foldersPath});
export const renameFolder = (directorioId:string, newName:string) => conservacionAPI.put(foldersURL + "/name", {directorioId, newName});
export const softDeleteFolder = (directorioId:string) => conservacionAPI.delete(foldersURL + "/softDelete", {params:{directorioId}});
export const hardDeleteFolder = (directorioId:string) => conservacionAPI.delete(foldersURL+'/hardDelete',{params:{directorioId}});
export const searchFolders = (cancelToken:CancelToken, searchText:string, from:number, numberFiles:number, orderBy:string, direction:string) => {
    return conservacionAPI.get(foldersURL + "/search", {cancelToken, params:{searchText, from, numberFiles, orderBy, direction}});
};
export const moveFolder = (directorioId:string, directorioDestinoId:string) => conservacionAPI.put(foldersURL + "/move", {directorioId, directorioDestinoId});
export const copyFolder = (directorioId:string, directorioDestinoId:string) => conservacionAPI.put(foldersURL + "/copy", {directorioId, directorioDestinoId});
export const updateFolderState = (directorioId:string, state:string) => conservacionAPI.put(foldersURL + '/state', {directorioId, state});


export const addInstruction = (instruction:string, directorioId:string) => conservacionAPI.post(foldersURL + '/instruction', {instruction,directorioId });
export const editInstruction = (instructionId:string, directorioId:string, newInstruction:string) => conservacionAPI.put(foldersURL + '/instruction', {instructionId,directorioId,newInstruction});
export const deleteInstruction = (instructionId:string, directorioId:string) => conservacionAPI.delete(foldersURL + '/instruction', {params:{ instructionId,directorioId }});


export const getAllDeletedDirectorios = () => conservacionAPI.get(foldersURL+ '/deleted');
export const restaurarDirectorio = (id:string) => conservacionAPI.put(foldersURL+ '/restaurar', {directorioId:id});
export const hardDeleteAllDirectorios = () => conservacionAPI.delete(foldersURL+ '/hardDeleteAll');

export const downloadFolderAsZip = (directorioId:string)=> conservacionAPI.get(foldersURL+ '/downloadZip', {params:{directorioId}, responseType:"blob"});

export const getAllFoldersStructure = () => conservacionAPI.get(foldersURL +'/structure');

export const blockFolder = (directorioId : string) => conservacionAPI.put(foldersURL + '/block', {directorioId});
export const unBlockFolder = (directorioId : string) => conservacionAPI.put(foldersURL + '/unblock', {directorioId});

//LLAMADA PARA GENERAR ENLACE DE DESCARGA
export const generateEnlaceToDownload = (directorios:string[], ficheros:string[]) => conservacionAPI.post('/enlace', {directorios, ficheros});
export const downloadContent = (enlaceId:string) => conservacionAPI.get('/enlace', {params:{enlaceId}, responseType:"blob"});


//LLAMADA PARA GENERAR ENLACES DIRECTOS A DIRECTORIOS
export const createAccesoDirecto = (directorioId:string, nombre:string, parentId:string) => conservacionAPI.post('/enlaceDirecto', {directorioId, nombre, parentId});
export const deleteAccesoDirecto = (enlaceId:string) => conservacionAPI.delete('/enlaceDirecto', {params:{enlaceId}});
export const moveAccesoDirecto = (enlaceId:string, directorioId:string) => conservacionAPI.put('/enlaceDirecto/move', {enlaceId, directorioId});
export const renameAccesoDirecto = (enlaceId:string, nombre:string) => conservacionAPI.put('/enlaceDirecto/rename', {enlaceId, nombre});


//LLAMADA COPIA DE SEGURIDAD
export const downloadLastSecurityCopy = () => conservacionAPI.get(foldersURL + '/downloadSecurityCopy');