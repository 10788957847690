import React, { useContext, useState } from 'react';
import styles from './Instrucciones.module.css';
import addInstruccionIcon from '../../../assets/plus.svg';

import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencilAlt, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import ModalDeleteInstruction from "./ModalDeleteInstruction";
import ModalAddInstruction from "./ModalAddInstruction";
import {FileSystemContext} from "../../../utils/FileSystemContext";
import {UserContext} from "../../../utils/UserContext";


const Instrucciones  = () => {

    const {user} = useContext(UserContext);
    const {actualLevel, changeLevel} = useContext(FileSystemContext);

    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [openModalAdd, setOpenModalAdd] = useState(false);
    const [editingInstruction, setEditingInstruction] = useState<{_id:string,text:string, creation_date:number, created_by?:{userID:string, name:string}} | null>(null);


    const onEndAction = (instrucciones: {_id:string, text:string, creation_date:number}[]) => {
        let sortInstructions = instrucciones.sort((a,b) => b.creation_date - a.creation_date)
        changeLevel({ ...(actualLevel as { _id: string; contenido: any[]; name: string }), instructions: sortInstructions });
        setEditingInstruction(null);
    };

    const handleOpenEdit = (inst:{_id:string, text:string, creation_date:number}) => {
        setOpenModalAdd(true);
        setEditingInstruction(inst);
    };

    const handleOpenDelete = (inst:{_id:string, text:string, creation_date:number}) => {
        setOpenModalDelete(true);
        setEditingInstruction(inst);
    }

    return <React.Fragment>
        <div className={styles.positsContainer}>
            {
                actualLevel?._id && <React.Fragment>
                    <img src={addInstruccionIcon} alt={"Icono de más"} height={'15px'} onClick={()=> setOpenModalAdd(true)}/>
                    {
                        actualLevel?.instructions &&  actualLevel.instructions.map(instruccion => {
                            return <div  className={styles.positInstruction} key={instruccion._id}>
                                <div>
                                    <div style={{display:'grid'}}>
                                        <span>{instruccion.created_by?.name}</span>
                                        <b>{moment(instruccion.creation_date).format('DD/MM/YYYY')}</b>
                                    </div>
                                    <div className={styles.icons}>
                                        <FontAwesomeIcon icon={faPencilAlt} onClick={()=>handleOpenEdit(instruccion)}/>
                                        { user?.admin && <FontAwesomeIcon icon={faTrashAlt} style={{marginLeft:'12px'}} onClick={()=>handleOpenDelete(instruccion)}/>}
                                    </div>
                                </div>
                                <div>
                                    {instruccion.text}
                                </div>
                            </div>
                        })
                    }
                    {openModalDelete && editingInstruction && <ModalDeleteInstruction instructionId={editingInstruction._id} directorioId={actualLevel._id} open={openModalDelete} setOpen={setOpenModalDelete} onDelete={onEndAction}/>}
                    {openModalAdd && <ModalAddInstruction open={openModalAdd} setOpen={setOpenModalAdd} instruction={editingInstruction} directorioId={actualLevel._id}  onEndAction={onEndAction}/>}
                </React.Fragment>
            }
        </div>

    </React.Fragment>
}

export default Instrucciones;
