import React, {createContext, Dispatch, FunctionComponent, SetStateAction, useState} from 'react';

type Filters = {pdf:boolean, doc:boolean, excel:boolean, other:boolean, folders:boolean};
type States = {sinProcesar:boolean, pendiente:boolean, aceptado:boolean, rechazado:boolean};

type orderByOptions = {attr:"name" | "creation_date" | "updated_date" | "size" | "deleted_date", direction:"asc" | "desc"};
type Context = {
    filters: Filters;
    changeFilters: Dispatch<SetStateAction<Filters >>;
    orderBy:orderByOptions,
    changeOrderBy:Dispatch<SetStateAction<orderByOptions>>;
    states:States;
    changeStates:Dispatch<SetStateAction<States>>;
};

let initialFilters = { pdf:true,doc:true,excel:true,other:true,folders:true };
let initialStates = {sinProcesar:true, pendiente:true, aceptado:true, rechazado:true};

export const FilterContext = createContext<Context>({filters: initialFilters, changeFilters: () => {}, orderBy:{attr:"name", direction:"asc"}, changeOrderBy:()=>{}, states:initialStates, changeStates:()=>{}});


export const FilterProvider: FunctionComponent = (props) => {
    const [filters, setFilters] = useState<Filters>(initialFilters);
    const [orderBy, setOrderBy] = useState<orderByOptions>({attr:"name", direction:"asc"});
    const [states, setStates] = useState<States>(initialStates);

    return <FilterContext.Provider value={{filters: filters, changeFilters: setFilters, orderBy:orderBy, changeOrderBy:setOrderBy, states:states, changeStates:setStates}}>{props.children}</FilterContext.Provider>;
};
