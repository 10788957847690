import React, { ChangeEvent, useContext, useRef, useState } from 'react';
import { FileSystemContext } from '../../utils/FileSystemContext';
import useFilesActions from "../../utils/useFilesActions";
import './UserArea.css';
import styles from './UserArea.module.css';

//Import Components
import Nivel from '../Nivel/Nivel';
import Header from "../Header/Header";
import UserAreaHeader from "./UserAreaHeader";
import ModalCreateFolder from "./ModalCreateFolder";
import PlusButtonDropdown from "./PlusButtonDropdown";

//Import Icons
import {  faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Instrucciones from "./Instrucciones/Instrucciones";
import { NotificationContext } from '../../utils/NotificationContext';
import { UserContext } from '../../utils/UserContext';

function UserArea() {
	const [plusButtonMenu, setPlusButtonMenu] = useState(false);
	const plusButtonRef = useRef<HTMLLabelElement>(document.createElement('label'));

	const { user } = useContext(UserContext);
	const { actualLevel, updateUploadProgress } = useContext(FileSystemContext);

	const {uploadFile } = useFilesActions();

	const {setActive, setText, setType} = useContext(NotificationContext);

	//OPEN MODAL
	const [modalCreateFolder, setModalCrateFolder] = useState(false);


	//TOGGLE GRID VIEW
	const [displayMode, setDisplayMode] = useState<"grid" | "table">('table');

	//TOGGLE PLUS BUTTON
	const togglePlusButton = () => {
		setPlusButtonMenu(!plusButtonMenu);
	};


	//FUNCION PARA ABRIR EL MODAL DE CREACION DE CARPETA
	const handleCreateFolder = () => {
		setModalCrateFolder(true);
		setPlusButtonMenu(false);
	};

	//FUNCION PARA SUBIR FICHERO
	const handleUploadFile = (e: ChangeEvent<HTMLInputElement>) => {
		setPlusButtonMenu(false);
		let promisesFiles = [];
		if(e.target.files && actualLevel){
			//Progreso de subida de ficheros
			updateUploadProgress({type:'addFiles', files:e.target.files.length});
			for(let i=0;i<e.target.files.length;i++){
				promisesFiles.push(uploadFile(e.target.files[i],actualLevel._id, actualLevel.name));
			}
		}
		Promise.all(promisesFiles).then(() => {
			setType('success');
			setText("Se han subido correctamente los ficheros.");
			setActive(true);
		}).catch(err => {
			console.error(err);
			setType('error');
			setText("Ha ocurrido algún error en el servidor.");
			setActive(true);
		})
	};


	return (
		<React.Fragment>
			<Header />
			<div className={'userArea_container'}>
				{/* --- Parte arriba (Atrás, Breadcrumb, Cambio de vista, etc...) Si tengo algo en el buscador no lo muestro --- */}
				<UserAreaHeader displayMode={displayMode} handleChangeView={setDisplayMode}/>
				<div className={styles.nivelContainer}>
					{/*--- Parte para las instrucciones en forma de posit ---*/}
					<Instrucciones />
					{/* --- Parte de la pantalla con los ficheros y toda la lógica de la neavegación entre niveles --- */}
					<Nivel displayMode={displayMode} />

				</div>
			</div>
			{actualLevel?._id && !(!!user?.readOnly?.length && user.readOnly.findIndex(dir => dir === actualLevel?._id) !== -1) && 
			(
				<React.Fragment>
					{/* --- Botón de "más" y menú deplegable --- */}
					<label className='plusButton' onClick={togglePlusButton} ref={plusButtonRef}>
						<FontAwesomeIcon icon={faPlusCircle} className='plus-icon' size={'3x'} />
					</label>
					<PlusButtonDropdown  open={plusButtonMenu} setOpen={setPlusButtonMenu} buttonRef={plusButtonRef.current} handleCreateFolder={handleCreateFolder} handleUploadFile={handleUploadFile} />

					{/* --- Modal para añadir una carpeta --- */}
					{modalCreateFolder && <ModalCreateFolder open={modalCreateFolder} setOpen={setModalCrateFolder}/>}
				</React.Fragment>
			)}
		</React.Fragment>
	);
}

export default UserArea;
