import React, {FormEvent, useContext, useState } from 'react';
import {UserContext} from "../../utils/UserContext";
import Header from "../Header/Header";
import Button from "../Button/Button";
import styles from './ChangePass.module.css';
import {updateUserPass} from "../../api/users";
import { NotificationContext } from '../../utils/NotificationContext';

const ChangePass = () => {
    const {user} = useContext(UserContext);
    const [formValues, setFormValues] = useState<{oldPass:string, newPass:string, repeatPass:string}>({oldPass:"", newPass:"", repeatPass:""});
    const [errors, setErrors] = useState({repeatPass:""});
    const {setType, setActive, setText} = useContext(NotificationContext);

    const onChange = (value:string , property:string) => {
        if(property !== 'oldPass'){
            setErrors({...errors, repeatPass:""});
        }
        setFormValues({...formValues, [property]:value});
    };

    const onSubmit = (e:FormEvent) => {
        e.preventDefault();
        if(formValues.newPass === formValues.repeatPass && user){
            updateUserPass(user._id, formValues.oldPass, formValues.newPass, formValues.repeatPass).then( response => {
                setFormValues({oldPass:"", newPass:"", repeatPass:""});
                setType('success');
                setText('Se ha modificado la contraseña correctamente.');
                setActive(true);
            }).catch(err => {
                console.error(err);
                setType('error');
                setText('Parece que algo ha fallado. Revisa la contraseña introducida.');
                setActive(true);
            });
        }else{
            setErrors({repeatPass:"Las contraseñas no concuerdan."});
        }
    };

    return <React.Fragment>
        <Header />
        <div>
            <span className={styles.title}>Cambiar contraseña</span>
            <form className={styles.form} onSubmit={onSubmit}>
                <input type={"password"} placeholder={"Anterior contraseña"} required value={formValues.oldPass} onChange={(e)=>onChange(e.target.value, 'oldPass')} />
                <input type={"password"} placeholder={"Nueva contraseña"} required value={formValues.newPass} onChange={(e)=>onChange(e.target.value, 'newPass')} />
                <div>
                    <input style={{width:'100%'}} type={"password"} className={errors.repeatPass ? "error" : ""} placeholder={"Repite la nueva contraseña"} required value={formValues.repeatPass} onChange={(e)=>onChange(e.target.value, 'repeatPass')} />
                    <label className='input_error'>{errors.repeatPass}</label>
                </div>
                <Button style={{width:'200px', margin:'0 auto'}} backgroundColor={"var(--main-color)"} contenido={"Cambiar contraseña"} />
            </form>
        </div>
    </React.Fragment>
};

export default ChangePass;
