import React, {Dispatch, FunctionComponent, useContext} from 'react';
import Modal from '../Modal/Modal';
import copyIcon from '../../assets/Contextmenu/Copy.svg';
import { NotificationContext } from '../../utils/NotificationContext';
import styles from './ModalEnlace.module.css';

type Props = {
    open:boolean,
    setOpen:Dispatch<boolean>,
    enlace:string
}


const ModalEnlace : FunctionComponent<Props> = (props) => {

    const {setText, setType, setActive} = useContext(NotificationContext);
    const handleCopyEnlace = () => {
        navigator.clipboard.writeText(props.enlace).then(() => {
            setText("Se ha copiado al portapapeles el enlace.");
            setType("success");
            setActive(true);
        }).catch(err => {
            setText("Error al copiar el enlace al portapapeles.");
            setType("error");
            setActive(true);
        });
    }

    return <Modal open={props.open} setOpen={props.setOpen}>
        <div className={styles.container}>
            <div className={styles.title}>
                <span>Enlace de descarga</span>
            </div>
            <div>
                <p className={styles.infoText}>Este es el enlace para descargar el contenido. Recuerda que este enlace estará disponible durante 30 días y podrá ser compartido usando el enlace abajo indicado.</p>
                <div className={styles.linkContainer}>{props.enlace} <img onClick={handleCopyEnlace} src={copyIcon} alt={"Icono para copiar contenido"}/></div>
            </div>
        </div>
    </Modal>
}

export default ModalEnlace;