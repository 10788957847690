import {conservacionAPI} from "./config";
import {Usuario} from "../utils/Types";

const usersURL = '/usuario';

export const getAllUsersGroupedByIntegral = () => conservacionAPI.get(usersURL + '/allGroupedIntegral');
export const createUser = (user:Usuario) => conservacionAPI.post(usersURL, user);
export const editUser = (user:Usuario) => conservacionAPI.put(usersURL, user);
export const deleteUser = (idUsuario:string) => conservacionAPI.delete(usersURL, {params:{ idUsuario }});
export const updateUserPass = (idUsuario:string, oldPass:string, newPass:string, repeatNewPass:string) => conservacionAPI.put(usersURL + '/password', {idUsuario:idUsuario, oldPass:oldPass, newPass:newPass,repeatPassword:repeatNewPass});
export const updatePermissions = (idUsuario:string, permisos:string[]) => conservacionAPI.put(usersURL + '/permissions', { idUsuario, permisos });
export const updateReadOnly = (idUsuario:string, readOnly:string[]) => conservacionAPI.put(usersURL + '/readOnly', { idUsuario, readOnly });