import React, {createContext, Dispatch, FunctionComponent, SetStateAction, useContext, useEffect, useRef, useState} from 'react';
import {searchFiles, searchFolders} from "../api/fileSystem";
import { FilterContext } from './FiltersContext';
import {FicheroType, Directorio} from "./Types";
import axios, {CancelTokenSource} from 'axios';

type Context = {
    searchValue:string;
    changeSearchValue:Dispatch<string>;
    searchContent: FicheroType[] | Directorio[] | null;
    changeSearchContent: Dispatch<SetStateAction<FicheroType[] | Directorio[] |null>>;
};

export const SearchContext = createContext<Context>({searchValue:"", changeSearchValue:()=>{}, searchContent:null, changeSearchContent:()=>{}});

let source =  axios.CancelToken.source();

export const SearchProvider: FunctionComponent = (props) => {
    const [searchValue, setSearchValue] = useState<string>("");
    const [searchContent, setSearchContent] = useState<FicheroType[] | Directorio[] |null>(null);
    const {filters} = useContext(FilterContext);

    let timerToSearchContent = useRef<NodeJS.Timeout>(setTimeout(()=>{}, 0));
    
    useEffect(()=>{
        if(source) {
            (source as CancelTokenSource).cancel();
        }
        //Si vuelvo a teclear, cancelo la anterior operacion y lanzo una nueva.
        if(timerToSearchContent.current) {
            clearTimeout(timerToSearchContent.current);
        }
        if(searchValue){
            //Aqui tengo algo en el buscador, pero en vez de hacerlo inmediatamente, voy a esperarme 200ms por si vuelvo a teclear.
            timerToSearchContent.current = setTimeout(() => {
                source = axios.CancelToken.source();
                let promises = [];
                promises.push(searchFiles((source as CancelTokenSource).token,searchValue, 0,400, "creation_date", "desc", filters.pdf, filters.doc, filters.other, filters.excel, filters.folders).then(response => {
                    return response.data.contenido;
                }));
                if(filters.folders){
                    promises.push(searchFolders((source as CancelTokenSource).token, searchValue, 0,400, "creation_date", "desc").then(response => {
                        return response.data.contenido;
                    }));
                }
                Promise.all(promises).then(values => {
                    let files = values[0] || [];
                    let folders = values[1] || [];
                    let allContent = folders.concat(files).map((item : any) => { return { _id:item._id, name:item.name, files:item.files, url:item.url, type:item.type,foldersPath:item.foldersPath, instructions:item.instructions, size:item.size, creation_date:item.creation_date, updated_date:item.updated_date }});
                    setSearchContent(allContent);
                }).catch(err => console.error(err));
            }, 200);
        }else{
            setSearchContent(null);
        }
    },[searchValue, setSearchContent, filters]);


    return <SearchContext.Provider value={{searchValue:searchValue,changeSearchValue:setSearchValue, searchContent:searchContent, changeSearchContent:setSearchContent}}>
        {props.children}
    </SearchContext.Provider>
}
