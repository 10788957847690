import React, { createContext, Dispatch, FunctionComponent, SetStateAction, useEffect, useState, useReducer} from "react";
import { Directorio, EnlaceDirecto, FicheroType } from "./Types";
import {socket} from '../api/fileSystem';

export type Instrucciones = {
    _id:string,
    text:string,
    creation_date:number,
    created_by?:{userID:string, name:string}
};

type FoldersPath = {
    _id:string,
    name:string,
    instructions?:Instrucciones[];
};

type AppHistory = {
    history:{_id:string, name:string, foldersPath:FoldersPath[], instructions:Instrucciones[]}[],
    active:number
}
type Context = {
    actualLevel: {_id:string, instructions?:Instrucciones[], contenido:any[], name:string, foldersPath?:FoldersPath[]} | null;
    changeLevel: Dispatch<SetStateAction<{_id:string, instructions?:Instrucciones[], contenido:any[], name:string, foldersPath?:FoldersPath[]} | null>>;
    levelHistory: {_id:string, name:string, instructions?:Instrucciones[]}[];
    portaPapeles:{action:"move" | "copy" | null , elements:(FicheroType | Directorio | EnlaceDirecto)[]},
    changePortapapeles:Dispatch<SetStateAction<{action:"move" | "copy" | null , elements:(FicheroType | Directorio | EnlaceDirecto)[]}>>;
    filesLoaded:number;
    changeFilesLoaded:Dispatch<SetStateAction<number>>;
    appHistory:AppHistory | null;
    changeAppHistory:Dispatch<{type:string, element?:{_id:string, name:string, foldersPath:FoldersPath[], instructions:Instrucciones[]}}>;
    uploadProgress:{ficherosPorSubir:number, ficherosSubidos:number, ficherosProcesados:number},
    updateUploadProgress:Dispatch<{type:string, files?:number}>
};

export const FileSystemContext = createContext<Context>({ 
    actualLevel: null, 
    changeLevel: () => {}, 
    levelHistory:[],
    portaPapeles:{action:null, elements:[]}, 
    changePortapapeles:()=>{}, 
    filesLoaded:0, 
    changeFilesLoaded:()=>{},
    appHistory:{history:[], active:0},
    changeAppHistory:()=>{},
    uploadProgress:{ficherosPorSubir:0, ficherosSubidos:0, ficherosProcesados:0},
    updateUploadProgress:() => {}
});
const historyReducer = (state: AppHistory, action:{type:string, element?:{_id:string, name:string, foldersPath:FoldersPath[], instructions:Instrucciones[]}}) => {
    switch(action.type){
        case 'avanza':
            return {...state, active: state.active +1};
        case 'atras':
            return {...state, active:state.active -1};
        case 'pushHistory':
            let newHistory = [...state.history];
            if(action.element){
                newHistory.push(action.element);
            }
            return { history:newHistory, active:newHistory.length - 1 }
        default:
            throw new Error();
    }
}

const uploadReducer = (state: {ficherosPorSubir:number, ficherosSubidos:number, ficherosProcesados:number}, action:{type:string, files?:number}) => {
    switch(action.type){
        default:
            throw new Error("No hay indicada una acción válida");
        case 'processFile':
            return {...state, ficherosProcesados:state.ficherosProcesados + 1};
        case 'uploadFile':
            return {...state, ficherosSubidos:state.ficherosSubidos + 1};
        case 'addFiles':
            return {...state, ficherosPorSubir:state.ficherosPorSubir + (action.files as number)}
        case 'reset':
            return {ficherosPorSubir:0, ficherosSubidos:0, ficherosProcesados:0}
    }
}
export const FileSystemProvider : FunctionComponent = (props) => {

    const [actualLevel, setActualLevel] = useState<{_id:string,instructions?:Instrucciones[], contenido:any[], name:string, foldersPath?:FoldersPath[]}| null>(null);
    const [levelHistory, setLevelHistory] = useState<{_id:string, name:string, instructions?:Instrucciones[]}[]>([]);
    const [portaPapeles, setPortapapeles] = useState<{action:"move" | "copy" | null , elements:(FicheroType | Directorio | EnlaceDirecto)[]} >({action:null, elements:[]});
    const [filesLoaded, setFilesLoaded] = useState<number>(0);
    const [appHistory, changeAppHistory] = useReducer(historyReducer, {history:[{_id:"", name:"Home", foldersPath:[], instructions:[]}], active:0});
    const [uploadProgress, setUploadProgress] = useReducer(uploadReducer, {ficherosPorSubir:0, ficherosSubidos:0, ficherosProcesados:0});

    //Funcion a ejecutar cada vez que cambie el nivel. Es decir cada vez que navegue por los directorios, ya sea una carpeta o ya sea por el breadcrum.
    useEffect(()=>{
        if(actualLevel !== null && !actualLevel.foldersPath){
            let newHistory = [...levelHistory];
            /*Si entro aquí es que el nivel no es nulo, por lo tanto algo haré en el historial.
            *    - Vamos a comprobar si el cambio es para alante, es decir, el nivel actual no está en el historial o por el contrario es para atrás, es decir, el nivel actual está en el historial.
            *    - Obteneemos la posición del nivel en el historial.
            *       -  Será la posicion X en caso de que exista [cambio hacia atrás]
            *       -  Será la posicion -1 en caso de que no exista. [cambio hacia delante]
            * */
            let levelPosition = levelHistory.findIndex((level) => level._id === actualLevel._id);
            if(levelPosition === -1){
                newHistory.push({_id:actualLevel._id,name:actualLevel.name, instructions:actualLevel.instructions as Instrucciones[]});
            }else{
                newHistory.splice(levelPosition+1);
            }
            setLevelHistory(newHistory);
        }
        if(actualLevel !== null && actualLevel.foldersPath){
            let newHistory : {_id:string, name:string, instructions?:Instrucciones[]}[] = ([{_id:"",name:"Home", instructions:[]}] as FoldersPath[]).concat((actualLevel.foldersPath as FoldersPath[])).concat([{_id:actualLevel._id, name:actualLevel.name, instructions:actualLevel.instructions as Instrucciones[]}]);
            setLevelHistory(newHistory);
        }
        if(!actualLevel){
            setLevelHistory([]);
        }
    },[actualLevel?.contenido]);


    useEffect(() => {
        //Progreso de subida de ficheros.
        socket.on('uploadProgress', (progreso) => {
            let percent = (progreso.loaded / progreso.total) * 100;
            if(percent === 100){
                setUploadProgress({type:'uploadFile'});
            }
        });

        socket.on('ficheroProcesado', (data) => {
            setUploadProgress({type:'processFile'});
        });
    }, []);

    useEffect(() => {
        if(uploadProgress.ficherosPorSubir !== 0 && uploadProgress.ficherosProcesados === 0 && uploadProgress.ficherosSubidos === 0){
            //Es la primera vez que vamos a iniciar el socket.
            socket.connect();
        }
        if(uploadProgress.ficherosPorSubir !== 0 && uploadProgress.ficherosPorSubir === uploadProgress.ficherosSubidos){
            //Se han subido todos los ficheros, voy a resetear todo tras uno segundos.
            setTimeout(() => {
                setUploadProgress({type:'reset'});
                socket.disconnect();
            }, 5000);
        }
        // if(uploadProgress.ficherosPorSubir)
    }, [uploadProgress]);


    return <FileSystemContext.Provider value={{ 
        actualLevel, 
        changeLevel: setActualLevel,
        levelHistory, 
        portaPapeles, 
        changePortapapeles:setPortapapeles, 
        filesLoaded, 
        changeFilesLoaded:setFilesLoaded,
        appHistory,
        changeAppHistory,
        uploadProgress,
        updateUploadProgress:setUploadProgress
    }}>{props.children}</FileSystemContext.Provider>;
};
