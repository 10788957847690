import React, { FunctionComponent, useState, useEffect } from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import styles from './Notification.module.css';

type Props = {
    text: string,
    type:'error' | 'success'
}

const Message: FunctionComponent<Props> = (props) => {

    const [hide, setHide] = useState(false);

    useEffect(() => {
        setTimeout(()=>{
            setHide(true);
        },1200);
        return () => { };
    }, []);

    return (
        <div className={`${styles.container} ${hide ? styles.hide : ""}`} style={{backgroundColor:props.type === "error" ? "#BD4949" : "#8ADE8D"}}>
            <FontAwesomeIcon icon={props.type === 'success' ? faCheckCircle : faTimesCircle} style={{marginRight:'8px'}}/>
            <span>{props.text}</span>
        </div>
    )
};

export default Message;
