import React, {useContext, useEffect} from 'react';
import './App.css';
import Login from './components/Login/login';
import UserArea from './components/UserArea/UserArea';
import loadingScreen from './components/Loading-Screen/loadingScreen'
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import { checkAuth } from './api/authentication';
import { conservacionAPI } from './api/config';
import Loader from './components/Loader/Loader';
import GestionarUsers from "./components/Users/GestionarUsers";
import {UserContext} from "./utils/UserContext";
import {NotificationContext} from './utils/NotificationContext';
import Notification from './components/Notification/Notification';
import Papelera from "./components/Papelera/Papelera";
import ChangePass from "./components/Users/ChangePass";
import DescargaContenido from './components/EnlacesDescarga/DescargaContenido';

function App() {
  const history = useHistory();
  const location = useLocation();

  const { changeUser } = useContext(UserContext);
  const { active, text, type } = useContext(NotificationContext);

  useEffect(() => {
    checkAuth().then((response) => {
        changeUser(response.data.user);
        if (location.pathname === "/login" || location.pathname === "/") {
          history.push('/user-area');
        }
      }).catch(() => {
        console.log('catch check');
          changeUser(null);
          if(!location.pathname.includes('downloadFiles')){
            console.log('redirect login');
            history.push('/login');
          }
      });

  },[]);


  return (
      <React.Fragment>
          <div className="App">
              <Switch>
                  <Route exact path={"/"} component={loadingScreen} />
                  <Route path="/login" component={Login} />
                  <Route path="/gestUsers" component={ GestionarUsers } />
                  <Route path={"/papelera"} component={Papelera}/>
                  <Route path={"/changePass"} component={ChangePass}/>
                  <Route path={"/downloadFiles"} component={DescargaContenido} />
                  <Route path="/user-area" component={UserArea} />
              </Switch>
          </div>
          <Loader instance={conservacionAPI}/>
          {active && <Notification text={text} type={type}/>}
      </React.Fragment>

  );
}

export default App;
