import React, {Dispatch, FunctionComponent} from 'react';
import Checkbox from '../CheckBox/Checkbox';
import styles from './TreeDirectory.module.css';

type Contenido = {
    _id:string, 
    name:string,
    hijos:Contenido[]
}
type Props = {
    contenido:Contenido[],
    selections:{[key:string]:{}},
    setSelections:Dispatch<{}>,
    readOnlySelections:{[key:string]:{}},
    setReadOnlySelections: Dispatch<{}>
}
const TreeDirectory : FunctionComponent<Props> = (props) => {

    const handleChangeSelection = (carpetaId:string) => {
        let nuevasClaves = {...props.selections};
        if(!!props.selections[carpetaId]){
            delete nuevasClaves[carpetaId];
        }else{
            nuevasClaves[carpetaId] = {}
        }
        props.setSelections(nuevasClaves);
    }

    const handleChangeReadOnlySelection = (carpetaId: string) => {
        let nuevasClaves = {...props.readOnlySelections};
        if(!!props.readOnlySelections[carpetaId]){
            delete nuevasClaves[carpetaId];
        }else{
            nuevasClaves[carpetaId] = {}
        }
        props.setReadOnlySelections(nuevasClaves);
    }


    return <div className={styles.mainContainer}>
        {props.contenido.map((carpeta: Contenido) => {
            
           return  <div key={carpeta._id} className={styles.subContainer}>
               <div className={styles.optionSelector}>
                   <Checkbox
                        type={'checkbox'}
                        name={carpeta.name}
                        checked={!!props.selections[carpeta._id]}
                        label={carpeta.name}
                        onChange={() => handleChangeSelection( carpeta._id )}/>
                <div className={styles.readOnlySelector}>
                        <Checkbox 
                            type={'checkbox'}
                            label={'R.O'}
                            name={'onlyRead__'+carpeta.name}
                            checked={!!props.readOnlySelections[carpeta._id]}
                            onChange={() => handleChangeReadOnlySelection(carpeta._id)}
                        />
                    </div>
                </div>
                { carpeta.hijos?.length > 0 && !!props.selections[carpeta._id] && <TreeDirectory contenido={carpeta.hijos} selections={props.selections} setSelections={props.setSelections} readOnlySelections={props.readOnlySelections} setReadOnlySelections={props.setReadOnlySelections}/> }
                
            </div>
        })}
    </div>

}

export default TreeDirectory;