import React, {useContext, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import './Header.css';
import {UserContext} from '../../utils/UserContext';
import {downloadLastSecurityCopy} from '../../api/fileSystem';

//IMPORT SVG
import logoSevillaNegro from '../../assets/logo-sevilla-negro.svg';
import romboMenu from '../../assets/grid.svg';

//IMPORT ICONS
import dropDownIcon from '../../assets/dropdownArrow.svg';
import {faSearch} from '@fortawesome/free-solid-svg-icons';
import {logout} from '../../api/authentication';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

//IMPORT COMPONENTS
import Dropdown from '../Dropdown/Dropdown';
import DropDownOption from '../Dropdown/DropdownOption';
import Filters from "./Filters";
import {SearchContext} from "../../utils/SearchContext";

const Header = () => {
	const [userMenu, setUserMenu] = useState(false);
	const [fileFilter, setFileFilter] = useState(false);

	const userInfoBtnRef = useRef<HTMLButtonElement>(document.createElement('button'));
	const fileFilterBtnRef = useRef<HTMLButtonElement>(document.createElement('button'));

	const {user, changeUser} = useContext(UserContext);
	const {searchValue, changeSearchValue} = useContext(SearchContext);

	let history = useHistory();

	//LOGOUT
	const handleLogout = () => {
		logout()
			.then(() => {
				changeUser(null);
				history.push('/login');
			})
			.catch((err) => {
				console.error(err);
			});
	};

	//OPEN USERMENU
	const toggleUserMenu = () => {
		setUserMenu(!userMenu);
	};

	//OPEN FILEFILTER
	const toggleFileFilter = () => {
		setFileFilter(!fileFilter);
	};

	//DESCARGAR COPIA SEGURIDAD
	const handleDownloadSecurityCopy = () => {
		downloadLastSecurityCopy().then(response => {
			let blob = new Blob([response.data], {type:"application/zip"});
            let link = document.createElement('a');
            link.href=window.URL.createObjectURL(blob);
            link.download =  "ccsev-backup.zip";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
			setUserMenu(false);
		}).catch(err => console.error(err));
	}

	return (
		<header className="navBar">
			<img className="black-logo" src={logoSevillaNegro} alt="Logo Sevilla" onClick={() => history.push('user-area')} />
			<div className="searchBar">
				<FontAwesomeIcon className="searchIcon" icon={faSearch} />
				<input type="text" placeholder="Buscar"  value={searchValue} onChange={(e)=>changeSearchValue(e.target.value)}/>
				<button className="romboIcon" onClick={toggleFileFilter} ref={fileFilterBtnRef}>
					<img src={romboMenu} alt="icono rombo" />
				</button>
				<Filters open={fileFilter} setopen={setFileFilter} btnRef={fileFilterBtnRef.current}/>
			</div>

			<div className="userID">
				<button className="userIDButton" onClick={toggleUserMenu} ref={userInfoBtnRef}>
					<span>{user?.dni} </span>
					<img src={dropDownIcon} alt="dropdown Icon" />
				</button>
				<Dropdown
					open={userMenu}
					setOpen={setUserMenu}
					direction={'bottom'}
					elementRef={userInfoBtnRef.current}
				>
					{user?.admin && <DropDownOption value={"Papelera"}  action={() => {history.push("papelera"); setUserMenu(false)}} />}
					{ user?.admin && <DropDownOption value={"Gestionar Usuarios"} action={() => {history.push("gestUsers"); setUserMenu(false)}}/> }
					<DropDownOption value={"Cambiar contraseña"} action={()=>{history.push("changePass") ; setUserMenu(false)}} />
					{user?.admin && <DropDownOption value={"Descargar copia de seguridad"}  action={handleDownloadSecurityCopy} />}
					<DropDownOption className={'noBottomBorder'} value={'Cerrar Sesión'} action={handleLogout} />
				</Dropdown>
			</div>
		</header>
	);
};

export default Header;
