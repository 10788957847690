import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { UserProvider } from './utils/UserContext';
import {NotificationProvider} from './utils/NotificationContext';
import {FileSystemProvider} from "./utils/FileSystemContext";
import { FilterProvider } from "./utils/FiltersContext";
import {SearchProvider} from "./utils/SearchContext";
import { BrowserRouter as Router } from 'react-router-dom';


ReactDOM.render(
  <React.StrictMode>
    <Router>
      <UserProvider>
        <NotificationProvider>
          <FilterProvider>
            <FileSystemProvider>
              <SearchProvider>
                <App />         
              </SearchProvider>
            </FileSystemProvider>
          </FilterProvider>  
        </NotificationProvider>
        </UserProvider>
      </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
