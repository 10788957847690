//Función para convertir de Bytes hasta GB
export const convertSize: (miTamaño: number) => string = (element) => {
	let respuesta: number = element;
	let formato: string = ' B';
	let listaFormatos: Array<string> = [' KB', ' MB', ' GB'];

	for (let temp = 0; respuesta > 1024; temp++) {
		respuesta = respuesta / 1024;
		formato = listaFormatos[temp];
	}
	return respuesta.toFixed(2) + formato;
};
