import React, { createContext, Dispatch, FunctionComponent, SetStateAction, useState } from 'react';
type Context = {
	user: User | null;
	changeUser: Dispatch<SetStateAction<User | null>>;
};

export const UserContext = createContext<Context>({ user: null, changeUser: () => {} });

export type User = {
	_id: string;
	username: string;
	admin: boolean;
	dni:string;
	integral: string;
	permisos: string[];
	readOnly: string[];
};

export const UserProvider: FunctionComponent = (props) => {
	const [user, setUser] = useState<User | null>(null);

	return <UserContext.Provider value={{ user: user, changeUser: setUser }}>{props.children}</UserContext.Provider>;
};
