import React, {Dispatch, FunctionComponent} from 'react';
import Modal from "../../Modal/Modal";
import {deleteInstruction} from "../../../api/fileSystem";

type Props = {
    instructionId:string,
    directorioId:string,
    open:boolean,
    setOpen:Dispatch<boolean>,
    onDelete:(instrucciones:{_id:string, text:string, creation_date:number}[])=>void
}

const ModalDeleteInstruction : FunctionComponent<Props> = (props) => {

    const handleDeleteInstruction = () => {
        deleteInstruction(props.instructionId, props.directorioId).then(response => {
            props.setOpen(false);
            props.onDelete(response.data.directorio.instructions);
        }).catch(err => console.error(err));
    };


    return <Modal open={props.open} setOpen={props.setOpen}>
        <div className={"addFolderModal"}>
            <div className={"modalTitle"}>
                <span> Eliminar instrucción</span>
            </div>
            <span>¿Está seguro que desea eliminar esta instrucción?</span>
            <div className={"modalBtns_container"}>
                <button className={"modalBtn"} style={{backgroundColor:"#9B9B9B"}} onClick={()=> props.setOpen(false)}>Cancelar</button>
                <button className={"modalBtn"} style={{backgroundColor:"var(--main-color)"}} onClick={handleDeleteInstruction}>Eliminar</button>
            </div>
        </div>
    </Modal>
}

export default ModalDeleteInstruction;
