import React, { useState, useEffect } from 'react';
import {useLocation} from 'react-router-dom';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import { downloadContent } from '../../api/fileSystem';

const DescargaContenido = () => {
    const query = new URLSearchParams(useLocation().search);

    const [isDownload, setIsDownload] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const handleDownload = () => {
        downloadContent(query.get('downloadUrl') as string).then(response => {
            let blob = new Blob([response.data], {type:"application/zip"});
            let link = document.createElement('a');
            link.href=window.URL.createObjectURL(blob);
            link.download = (query.get('downloadUrl') as string) + ".zip";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setIsDownload(true);
        }).catch(err => console.error(err));
    }

    useEffect(() => {
        setTimeout(() => setShowModal(true), 200);
    }, [])
    return <div>
        <Modal open={showModal} setOpen={()=>{}}>
            {
                !isDownload ? 
                    <Button contenido={"Descargar"} action={handleDownload}/> 
                : 
                    <div style={{color:'var(--main-color)'}}>
                        Ya se ha descargado el contenido. Muchas gracias.
                    </div>
            }
        </Modal>
    </div>
};

export default DescargaContenido;